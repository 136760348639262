import {
  CreateButton,
  EditButton,
  DeleteButton,
  List,
  Space,
  Table,
  useDrawerForm,
  useTable,
} from "@pankod/refine-antd";
import { ShowLink } from "components/actionLink";
import { Project } from "interfaces/dirac/connect/depot/v1alpha1/project";
import { Group } from "interfaces/dirac/connect/depot/v1alpha1/group";
import { GroupCreate } from "./create";

export function GroupList() {
  const { tableProps } = useTable<Group>();
  const {
    drawerProps,
    formProps,
    saveButtonProps,
    show: createShow,
  } = useDrawerForm<Project>({ action: "create" });

  return (
    <>
      <List
        pageHeaderProps={{
          extra: <CreateButton onClick={() => createShow()} />,
        }}
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column<Group>
            dataIndex="title"
            title="Name"
            render={(title, record) => (
              <ShowLink recordItemId={record.name}>
                {title || <em>Unnamed</em>}
              </ShowLink>
            )}
          />
          <Table.Column<Group>
            title="Actions"
            render={(_, record) => {
              return (
                <>
                  <Space>
                    <EditButton
                      size="small"
                      resourceName="groups"
                      recordItemId={record.name}
                    />
                    <DeleteButton
                      size="small"
                      resourceName="groups"
                      recordItemId={record.name}
                    />
                  </Space>
                </>
              );
            }}
          />
        </Table>
      </List>
      <GroupCreate
        drawerProps={drawerProps}
        formProps={formProps}
        saveButtonProps={saveButtonProps}
      />
    </>
  );
}
