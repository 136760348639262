import React, { StrictMode } from "react";
import ReactDOMClient from "react-dom/client";
import authProvider from "providers/authProvider";
import reportWebVitals from "./reportWebVitals";
import { Refine } from "@pankod/refine-core";
import { ErrorComponent, Layout, LoginPage } from "@pankod/refine-antd";
import { notificationProvider } from "./providers/notificationProvider";
import release from "./pages/release/release";
import file from "pages/file/list";
import user from "./pages/user/user";
import group from "./components/user/rolePicker";
import key from "./pages/key/key";
import event from "./pages/event/show";
import projectResource from "./pages/project/project";
import FADataProvider from "./providers/dataProvider";
import routerProvider from "./providers/routerProvider";
import FASider from "./components/refine/sider";
import "./styles/logo.less";
import "./styles/antd.dark.less";
import { ShowAbout } from "./pages/about";
import "dayjs/locale/sv";
import dayjs from "dayjs";
import accessProvider from "./providers/accessProvider";
import { ProjectProvider } from "./context/projectState";

dayjs.locale("en");

const dataProvider = new FADataProvider();

const App: React.FC = () => {
  return (
    <StrictMode>
      <ProjectProvider>
        <Refine
          options={{
            disableTelemetry: true,
          }}
          Title={({ collapsed }) => (
            <div className="logo">
              {collapsed ? (
                <>
                  <img
                    alt="Dirac"
                    src={process.env.PUBLIC_URL + "/logo_white_jewel.svg"}
                  />
                  <div className={"fm collapsed"}>Depot</div>
                </>
              ) : (
                <>
                  <img
                    alt="Dirac"
                    src={process.env.PUBLIC_URL + "/logo_white.svg"}
                  />
                  <div className={"fm"}>Depot</div>
                </>
              )}
            </div>
          )}
          Header={() => null}
          Sider={() => <FASider />}
          Layout={Layout}
          LoginPage={LoginPage}
          authProvider={authProvider}
          dataProvider={dataProvider}
          routerProvider={{
            ...routerProvider,
            routes: [
              {
                element: <ShowAbout />,
                layout: true,
                path: "/about",
              },
            ] as typeof routerProvider.routes,
          }}
          notificationProvider={notificationProvider}
          resources={[projectResource, file, release, user, group, key, event]}
          catchAll={<ErrorComponent />}
          accessControlProvider={accessProvider}
        />
      </ProjectProvider>
    </StrictMode>
  );
};

ReactDOMClient.createRoot(document.getElementById("root")!).render(<App />);

reportWebVitals();
