import { useList } from "@pankod/refine-core";
import { Transfer } from "@pankod/refine-antd";
import { User } from "interfaces/dirac/connect/depot/v1alpha1/user";

interface UserPickerProps {
  value?: string[];

  onChange?(value?: string[]): void;
}

export function UserPicker({ value, onChange }: UserPickerProps) {
  const { data } = useList<User>({
    resource: "users",
    metaData: {},
    config: {
      pagination: {
        pageSize: 1000,
      },
    },
    queryOptions: {},
  });

  return (
    <Transfer
      style={{ height: "35em" }}
      targetKeys={value}
      onChange={onChange}
      showSearch
      pagination
      rowKey={(item) => item.name}
      render={(item) => item?.emails[0].email}
      dataSource={data?.data}
    />
  );
}
