import { Edit, Form, Input, useForm } from "@pankod/refine-antd";
import { Group } from "interfaces/dirac/connect/depot/v1alpha1/group";
import { RolePicker } from "components/user/rolePicker";
import { UserPicker } from "components/user/userPicker";

export function GroupEdit() {
  const { saveButtonProps, formProps, queryResult } = useForm<Group>({
    resource: "",
  });

  return (
    <Edit
      isLoading={queryResult?.isLoading}
      saveButtonProps={saveButtonProps}
      canDelete
    >
      <Form {...formProps} wrapperCol={{ span: 14 }} layout="vertical">
        <Form.Item name="title" label="Name">
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input />
        </Form.Item>
        <Form.Item name="roles" label="Roles">
          <RolePicker />
        </Form.Item>
        <Form.Item name="users" label="Users">
          <UserPicker />
        </Form.Item>
      </Form>
    </Edit>
  );
}
