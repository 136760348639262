import { useCreate } from "@pankod/refine-core";
import {
  Button,
  UploadFile,
  UploadProps,
  Upload as AntdUpload,
} from "@pankod/refine-antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { useCallback } from "react";
import { useDepotUpload } from "hooks/data/upload";
import { useProject } from "../../context/projectState";

interface FileUploadProps extends Omit<UploadProps, "onChange"> {
  onUploaded?(file: UploadFile): void;
  accept?: string;
  value?: UploadFile[];
  prefix?: string;
}

export function FileUpload({
  onUploaded,
  value,
  prefix,
  ...props
}: FileUploadProps) {
  const { mutateAsync: createFile } = useCreate<File>();
  const [project] = useProject();
  const onUploadedCallback = useCallback(
    async (upload: any) => {
      await createFile({
        resource: "files",
        values: {
          parent: project,
          filename: (prefix ? prefix + "/" : "") + upload.name,
          upload: upload.uid,
        },
        successNotification: false,
      });
      onUploaded?.(upload);
    },
    [onUploaded, createFile, prefix, project]
  );

  const { isLoading, uploadProps } = useDepotUpload({
    onUploaded: onUploadedCallback,
    uploadProps: props,
  });

  return (
    <AntdUpload showUploadList={false} multiple {...uploadProps}>
      <Button loading={isLoading} icon={<CloudUploadOutlined />}>
        Upload
      </Button>
    </AntdUpload>
  );
}
