import { useMany, useNavigation, useShow } from "@pankod/refine-core";
import {
  Col,
  DeleteButton,
  Row,
  Show,
  Space,
  Typography,
} from "@pankod/refine-antd";
import { User } from "interfaces/dirac/connect/depot/v1alpha1/user";
import { Group } from "interfaces/dirac/connect/depot/v1alpha1/group";

const { Title, Text } = Typography;

export function GroupShow() {
  const { queryResult } = useShow<Group>();
  const record = queryResult.data?.data;
  const { list } = useNavigation();

  const { data: usersData } = useMany<User>({
    resource: "users",
    ids: record?.users.map((user) => user)!,
    queryOptions: {
      enabled: !!record?.users,
    },
  });

  return (
    <>
      <Show
        isLoading={queryResult?.isLoading}
        canDelete
        actionButtons={
          <>
            <DeleteButton
              recordItemId={record?.name}
              onSuccess={() => list("groups")}
            />
          </>
        }
      >
        <Space direction="vertical" size="large">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col sm={50}>
              <Title level={5}>Title</Title>
              <Text>{record?.title}</Text>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col sm={50}>
              <Title level={5}>Description</Title>
              <Text>{record?.description}</Text>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col sm={50}>
              <Title level={5}>Users</Title>
              <div>
                {usersData?.data.map((item, index) => (
                  <Text>
                    {index > 0 && ", "}
                    {item.emails[0].email}
                  </Text>
                ))}
              </div>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col sm={50}>
              <Title level={5}>Roles</Title>
              <div>
                {record?.roles.map((item, index) => (
                  <Text key={item}>
                    {index > 0 && ", "}
                    {item}
                  </Text>
                ))}
              </div>
            </Col>
          </Row>
        </Space>
      </Show>
    </>
  );
}
