import React from "react";
import dayjs from "dayjs";
import { Tooltip, Typography } from "@pankod/refine-antd";

import LocalizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);

type DateProps = {
  locales?: string;
  format?: string;
  value?: Date | string;
};

export type DateFieldProps = DateProps;

/**
 * This field is used to display dates. It uses {@link https://day.js.org/docs/en/display/format `Day.js`} to display date format.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/fields/date} for more details.
 */
export const DateTimeField: React.FC<DateFieldProps> = ({
  value,
  format: dateFormat = "YYYY-MM-DD HH:mm",
  ...rest
}) => {
  const { Text } = Typography;

  const d = dayjs(value);
  if (d.diff(new Date(), "hour") > -1) {
    return (
      <Tooltip title={d.format(dateFormat)}>
        <Text {...rest}>{d.fromNow()}</Text>
      </Tooltip>
    );
  }
  return <Text {...rest}>{d.format(dateFormat)}</Text>;
};
