import {
  CloudDownloadOutlined,
  KeyOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import {
  Create,
  CreateButton,
  Grid,
  Drawer,
  DrawerProps,
  FormProps,
  SelectProps,
  List,
  SaveButton,
  Table,
  useDrawerForm,
  Form,
  Input,
  Edit,
  Show,
  useForm,
  Switch,
  useSelect,
  Select,
  DeleteButton,
  Button,
  ButtonProps,
  Space,
  EditButton,
  Row,
  Col,
  Typography,
} from "@pankod/refine-antd";
import {
  IResourceItem,
  useList,
  useApiUrl,
  useUpdate,
  useNavigation,
  BaseKey,
  useShow,
  useMany,
} from "@pankod/refine-core";
import { ShowLink } from "components/actionLink";
import { Key } from "interfaces/dirac/connect/depot/v1alpha1/key";
import { ProjectPicker } from "components/project/picker";
import { Project } from "interfaces/dirac/connect/depot/v1alpha1/project";
import React, { useState } from "react";
import { useProject } from "context/projectState";

const KeyList = () => {
  const {
    drawerProps,
    formProps,
    saveButtonProps,
    show: createShow,
  } = useDrawerForm<Key>({ action: "create" });
  const [showArchived, setShowArchived] = useState(false);

  const { data: results } = useList<Key>({
    resource: "keys",
    config: {
      filters: [
        {
          field: "archived",
          operator: "eq",
          value: showArchived ? '"true"' : '"false"',
        },
      ],
    },
  });

  type KeyMutationResult = {
    id: number;
    title: string;
  };

  const { mutate } = useUpdate<KeyMutationResult>();

  const reviveKey = (key: Key) => {
    key.archived = false;

    mutate({
      resource: "keys",
      id: key.name,
      values: key,
    });
  };

  const handleShowArchived = (enable: boolean) => {
    setShowArchived(enable);
  };

  const renderButton = (record: Key) => {
    return (
      <Space>
        <EditButton
          size="small"
          resourceName="keys"
          recordItemId={record.name}
        />
        {record.archived ? (
          <Button
            resource="keys"
            title="Undo"
            size="small"
            danger={false}
            onClick={(e) => reviveKey(record)}
            icon={<UndoOutlined />}
          >
            Revive
          </Button>
        ) : (
          <DeleteButton
            confirmOkText="Archive"
            title="Archive"
            size="small"
            resourceName="keys"
            recordItemId={record.name}
          >
            Archive
          </DeleteButton>
        )}
      </Space>
    );
  };
  return (
    <>
      <List
        pageHeaderProps={{
          extra: <CreateButton onClick={() => createShow()} />,
        }}
      >
        <Form
          layout="inline"
          className="components-table-demo-control-bar"
          style={{ marginBottom: 16 }}
        >
          <Form.Item label="Show Archived">
            <Switch checked={showArchived} onChange={handleShowArchived} />
          </Form.Item>
        </Form>
        <Table rowKey="name" dataSource={results?.data}>
          <Table.Column<Key>
            dataIndex="title"
            title="Name"
            render={(_, record) => (
              <ShowLink recordItemId={record.name}>{record.title}</ShowLink>
            )}
          />
          <Table.Column dataIndex="kind" title="Kind" />
          <Table.Column dataIndex="source" title="Source" />
          <Table.Column<Key>
            title="Actions"
            render={(_, record) => renderButton(record)}
          />
        </Table>
      </List>
      <KeyCreate
        saveButtonProps={saveButtonProps}
        formProps={formProps}
        drawerProps={drawerProps}
      />
    </>
  );
};

interface KeyCreateProps {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: React.ComponentProps<typeof SaveButton>;
}

const KeyEdit = () => {
  const { queryResult } = useForm<Key>();
  const { list } = useNavigation();
  const { formProps, saveButtonProps } = useForm<Key>();
  return (
    <Edit
      saveButtonProps={saveButtonProps}
      actionButtons={
        <Space style={{ float: "right", marginRight: 24 }}>
          <DownloadButton dlId={queryResult?.data?.data?.name!}>
            Download key
          </DownloadButton>
          <DeleteButton
            recordItemId={queryResult?.data?.data?.name}
            onSuccess={() => list("keys")}
          />
          <SaveButton htmlType="submit" {...saveButtonProps} />
        </Space>
      }
    >
      <Form {...formProps} wrapperCol={{ span: 14 }} layout="vertical">
        <Form.Item name="title" label="Name">
          <Input />
        </Form.Item>
        <Form.Item name="projects" label="Projects">
          <ProjectPicker />
        </Form.Item>
      </Form>
    </Edit>
  );
};

const { Title, Text } = Typography;
const KeyShow = () => {
  const { queryResult } = useShow<Key>();
  const { list } = useNavigation();
  const record = queryResult.data?.data;
  const { data: projData } = useMany<Project>({
    resource: "projects",
    ids: record?.projects!,
    queryOptions: {
      enabled: !!record?.projects,
    },
  });

  const projectArr = projData ? projData.data! : [];
  const projDict = projData
    ? Object.fromEntries(projectArr.map((t) => [t.name, t.title]))
    : {};

  type TableRow = {
    title: string;
  };
  const projectsData = record?.projects.map(
    (proj): TableRow => ({
      title: projDict[proj],
    })
  );
  type ProjColumn = {
    title: string;
    dataIndex: string;
    key: string;
  };

  const projectColumns: ProjColumn[] = [
    {
      title: "Project",
      dataIndex: "title",
      key: "title",
    },
  ];

  return (
    <Show
      actionButtons={
        <Space style={{ float: "right", marginRight: 24 }}>
          <DownloadButton dlId={queryResult?.data?.data?.name!}>
            Download key
          </DownloadButton>
          <DeleteButton
            recordItemId={queryResult?.data?.data?.name}
            onSuccess={() => list("keys")}
          />
        </Space>
      }
    >
      <Space direction="vertical" size="large">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col sm={20}>
            <Title level={5}>Name</Title>
            <Text style={{ whiteSpace: "nowrap" }}>
              {queryResult.data?.data?.title}
            </Text>
          </Col>
        </Row>
        {queryResult.data?.data?.projects && (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col sm={150}>
              <Title level={5}>Projects</Title>
              <Table
                dataSource={projectsData}
                columns={projectColumns}
                pagination={false}
                showHeader={false}
              />
            </Col>
          </Row>
        )}
      </Space>
    </Show>
  );
};

const KeyCreate: React.FC<KeyCreateProps> = ({
  drawerProps,
  saveButtonProps,
  formProps,
}) => {
  const breakpoint = Grid.useBreakpoint();
  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
    >
      <Create
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{ style: { background: "transparent" } }}
      >
        <Form {...formProps} layout="vertical">
          <Form.Item name="title" label="Name">
            <Input />
          </Form.Item>
          <Form.Item name="projects" label="Projects">
            <ProjectPicker />
          </Form.Item>
        </Form>
      </Create>
    </Drawer>
  );
};

const resourceProps: IResourceItem = {
  name: "keys",
  icon: <KeyOutlined />,
  list: KeyList,
  edit: KeyEdit,
  show: KeyShow,
};

interface KeySelectProps extends SelectProps {}

export const KeySelect: React.FC<KeySelectProps> = (props) => {
  const [project] = useProject();
  const { selectProps } = useSelect({
    filters: [
      {
        field: "project",
        operator: "eq",
        value: `"${project}"`,
      },
      {
        field: "archived",
        operator: "eq",
        value: '"false"',
      },
    ],
    resource: "keys",
    optionLabel: "title",
    optionValue: "name",
  });
  return <Select {...selectProps} {...props} />;
};

export default resourceProps;

interface DownloadButtonProps extends ButtonProps {
  dlId: BaseKey | undefined;
}

export function DownloadButton(props: DownloadButtonProps) {
  const { dlId, children, ...rest } = props;

  const apiUrl = useApiUrl();

  return (
    <Button
      href={apiUrl + "/" + dlId + ":download"}
      icon={<CloudDownloadOutlined />}
      download
      {...rest}
    >
      {children ?? "Download"}
    </Button>
  );
}
