import {
  useApiUrl,
  useNavigation,
  useNotification,
  useOne,
} from "@pankod/refine-core";
import {
  Button,
  DeleteButton,
  Edit,
  Form,
  Input,
  SaveButton,
  useForm,
} from "@pankod/refine-antd";
import { SafetyOutlined } from "@ant-design/icons";
import { DownloadButton } from "components/file/downloadButton";
import { useEffect, useState } from "react";
import { File } from "interfaces/dirac/connect/depot/v1alpha1/file";
import { LabelInput } from "components/label";
import { SignModal } from "components/file/signModal";
import { AxiosResponse } from "axios";
import { Operation } from "interfaces/dirac/connect/depot/v1alpha1/operation";
import { httpClient } from "providers/dataProvider";
import { DarAnalysisCard } from "./list";

export function FileEdit() {
  const apiUrl = useApiUrl();
  const { formProps, saveButtonProps, queryResult, id } = useForm<File>();
  const { list } = useNavigation();
  const [signModalVisible, setSignModalVisible] = useState(false);
  const [signForm] = Form.useForm();
  const { show, edit } = useNavigation();
  const [analysisOperation, setAnalysisOperation] = useState<string | null>(
    null
  );
  const { isLoading: isAnalysisOperationLoading, data: analysisData } =
    useOne<Operation>({
      resource: "operations",
      id: analysisOperation!,
      queryOptions: {
        refetchInterval: 1000,
        enabled: !!analysisOperation,
      },
    });
  const { open: openNotification } = useNotification();

  const isAnalysisDone =
    !!analysisOperation && analysisData?.data?.done === true;
  const isAnalysisLoading =
    !!analysisOperation &&
    (isAnalysisOperationLoading || analysisData?.data?.done === false);

  // Refetch file when analysis is fetched.
  useEffect(() => {
    if (isAnalysisDone) {
      queryResult?.refetch();
      setAnalysisOperation(null);

      if (openNotification) {
        openNotification({
          type: "success",
          description: "Analysis done",
          message: "The file was analyzed successfully",
        });
      }
    }
  }, [queryResult, isAnalysisDone, setAnalysisOperation, openNotification]);

  const file = queryResult?.data?.data;

  return (
    <>
      <Edit
        canDelete
        isLoading={queryResult?.isLoading}
        actionButtons={
          <>
            {file?.type === "dar" && (
              <Button
                onClick={() => {
                  signForm.resetFields();
                  setSignModalVisible(true);
                }}
                icon={<SafetyOutlined />}
              >
                Sign
              </Button>
            )}
            <DownloadButton file={queryResult?.data?.data?.name} />
            <DeleteButton
              recordItemId={queryResult?.data?.data?.name}
              onSuccess={() => list("files")}
            />
            <SaveButton htmlType="submit" {...saveButtonProps} />
          </>
        }
      >
        <Form {...formProps} wrapperCol={{ span: 14 }} layout="vertical">
          <Form.Item name="filename" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="labels" label="Labels" shouldUpdate>
            <LabelInput />
          </Form.Item>
        </Form>
      </Edit>

      {file?.type === "dar" && (
        <DarAnalysisCard
          file={file}
          isAnalysisLoading={isAnalysisLoading}
          onAnalyze={async () => {
            const response = await httpClient.post<
              any,
              AxiosResponse<Operation>
            >(`${apiUrl}/${id}:process`, {
              type: "analyze",
            });
            setAnalysisOperation(response?.data?.name);
          }}
        />
      )}

      <SignModal
        onChange={(name) => {
          setSignModalVisible(false);
          if (openNotification) {
            openNotification({
              type: "success",
              description: "Signed",
              message: "A signed copy of the file was successfully created",
            });
          }
          show("files", name, "replace");
          setTimeout(() => edit("files", name, "replace"), 0);
        }}
        onError={(err) => {
          setSignModalVisible(false);
          openNotification?.({
            type: "error",
            description: `Error ${err.code}`,
            message: err.message,
          });
        }}
        onCancel={() => setSignModalVisible(false)}
        file={queryResult?.data?.data.name}
        signModalVisible={signModalVisible}
        filename={queryResult?.data?.data.filename!}
      />
    </>
  );
}
