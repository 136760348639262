import React, { useEffect, useState } from "react";
import { Form, Input, Modal } from "@pankod/refine-antd";
import { CanAccess, useApiUrl, useOne } from "@pankod/refine-core";
import { Operation } from "interfaces/dirac/connect/depot/v1alpha1/operation";
import { KeySelect } from "../../pages/key/key";
import { httpClient } from "../../providers/dataProvider";
import { Status } from "interfaces/google/rpc/status";

interface SignModalProps {
  onChange?: (value: string) => void;
  onError?: (error: Status) => void;
  onCancel?: () => void;
  signModalVisible: boolean;
  file?: string;
  filename?: string;
}

export const SignModal: React.FC<SignModalProps> = ({
  signModalVisible,
  file,
  filename,
  onError,
  onChange,
  onCancel,
}) => {
  const apiUrl = useApiUrl();
  const [operationID, setOperationID] = useState<string | null>(null);
  const [signForm] = Form.useForm();
  const { data: operationData } = useOne<Operation>({
    resource: "operations",
    id: operationID!,
    queryOptions: {
      refetchInterval: 1000,
      enabled: !!operationID,
    },
  });

  const operation = operationData?.data;
  const isSigning = operation?.done === false;

  useEffect(() => {
    if (operationID && operation?.done) {
      if (operation.error) {
        console.log("We got an error", operation);

        onError?.(operation.error);
        setOperationID(null);
      } else {
        onChange?.((operation.response as any)?.name);
        setOperationID(null);
      }
    }
  }, [operationID, operation, onChange, onError]);

  const signedFilename = filename?.replace(/(\.[\w\d_-]+)$/i, "_signed$1")!;

  return (
    <Modal
      visible={signModalVisible}
      title="Sign"
      confirmLoading={isSigning}
      onCancel={() => {
        signForm.resetFields();
        setOperationID(null);
        onCancel?.();
      }}
      onOk={async () => {
        const values = await signForm.validateFields();
        const resp = await httpClient.post<Operation>(
          `${apiUrl}/${file}:process`,
          { type: "sign", ...values }
        );

        setOperationID(resp.data?.name);
      }}
    >
      <Form
        form={signForm}
        layout="vertical"
        initialValues={{ spec: { filename: signedFilename } }}
      >
        <Form.Item
          label="Key"
          name={["spec", "key"]}
          rules={[{ required: true }]}
        >
          <KeySelect />
        </Form.Item>
        <Form.Item
          label="Output filename"
          name={["spec", "filename"]}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <CanAccess resource={"spec"} action={"show"}>
          <Form.Item label="Spec" shouldUpdate>
            {(instance) => {
              return (
                <pre
                  style={{
                    background: "rgba(0,0,0,0.5)",
                    padding: "1em",
                  }}
                >
                  <code>
                    {JSON.stringify(instance.getFieldsValue(), null, 4)}
                  </code>
                </pre>
              );
            }}
          </Form.Item>
        </CanAccess>
      </Form>
    </Modal>
  );
};
