import { IResourceItem } from "@pankod/refine-core";
import { Transfer } from "@pankod/refine-antd";
import { TeamOutlined } from "@ant-design/icons";
import { GroupList } from "../../pages/group/list";
import { GroupShow } from "../../pages/group/show";
import { GroupEdit } from "../../pages/group/edit";

const resourceProps: IResourceItem = {
  name: "groups",
  label: "Groups",
  icon: <TeamOutlined />,
  list: GroupList,
  edit: GroupEdit,
  show: GroupShow,
};

export default resourceProps;

interface RolePickerProps {
  value?: string[];

  onChange?(value?: string[]): void;
}

export function RolePicker({ value, onChange }: RolePickerProps) {
  const roles = [
    { key: "writer", title: "writer" },
    { key: "reader", title: "reader" },
    {
      key: "signer",
      title: "signer",
    },
  ];
  return (
    <Transfer
      style={{ height: "11em" }}
      targetKeys={value}
      onChange={onChange}
      rowKey={(item) => item.key}
      render={(item) => item?.title}
      dataSource={roles}
    />
  );
}
