import React, { ReactElement, useState } from "react";
import {
  IResourceItem,
  useList,
  useMany,
  useNavigation,
  useOne,
  useShow,
} from "@pankod/refine-core";
import {
  Button,
  Col,
  Create,
  CreateButton,
  EditButton,
  DeleteButton,
  Drawer,
  DrawerProps,
  Edit,
  Form,
  FormProps,
  Grid,
  Input,
  List,
  Result,
  Row,
  SaveButton,
  Select,
  SelectProps,
  Show,
  Space,
  Table,
  Typography,
  useDrawerForm,
  useForm,
  useTable,
} from "@pankod/refine-antd";
import {
  ExperimentOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Project } from "interfaces/dirac/connect/depot/v1alpha1/project";
import { User } from "interfaces/dirac/connect/depot/v1alpha1/user";
import { Group } from "interfaces/dirac/connect/depot/v1alpha1/group";
import { useDebounce } from "use-debounce";
import { useProject } from "../../context/projectState";

const { Title, Text } = Typography;

const resourceProps: IResourceItem = {
  name: "projects",
  label: "Projects",
  icon: <ExperimentOutlined />,
  list: ProjectList,
  edit: ProjectEdit,
  show: ProjectShow,
};

export default resourceProps;

const ProjectCreate: React.FC<ProjectCreateProps> = ({
  drawerProps,
  saveButtonProps,
  formProps,
}) => {
  const breakpoint = Grid.useBreakpoint();
  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
    >
      <Create
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{ style: { background: "transparent" } }}
      >
        <Form {...formProps} layout="vertical">
          <Form.Item name="title" label="Name">
            <Input />
          </Form.Item>
          <Form.Item label="User access">
            <UserPermissions />
          </Form.Item>
          <Form.Item label="Group access">
            <GroupPermissions />
          </Form.Item>
        </Form>
      </Create>
    </Drawer>
  );
};

function ProjectList() {
  const [, setProject] = useProject();
  const { tableProps } = useTable<Project>();
  const { list } = useNavigation();
  const {
    drawerProps,
    formProps,
    saveButtonProps,
    show: createShow,
  } = useDrawerForm<Project>({ action: "create" });
  return (
    <>
      <List
        pageHeaderProps={{
          extra: <CreateButton onClick={() => createShow()} />,
        }}
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column<Project>
            dataIndex="title"
            title="Name"
            render={(title, record) => (
              <Typography.Link
                onClick={() => {
                  setProject(record.name);
                  list("files");
                }}
              >
                {title || <em>Unnamed</em>}
              </Typography.Link>
            )}
          />
          <Table.Column<Project>
            title="Actions"
            render={(_, record) => {
              return (
                <>
                  <Space>
                    <EditButton
                      size="small"
                      resourceName="projects"
                      recordItemId={record.name}
                    />
                    <DeleteButton
                      size="small"
                      resourceName="projects"
                      recordItemId={record.name}
                    />
                  </Space>
                </>
              );
            }}
          />
        </Table>
      </List>
      <ProjectCreate
        drawerProps={drawerProps}
        formProps={formProps}
        saveButtonProps={saveButtonProps}
      />
    </>
  );
}

interface ProjectCreateProps {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: React.ComponentProps<typeof SaveButton>;
}

function ProjectEdit() {
  const { saveButtonProps, formProps, queryResult } = useForm<Project>({
    resource: "",
  });

  return (
    <Edit
      isLoading={queryResult?.isLoading}
      saveButtonProps={saveButtonProps}
      canDelete
    >
      <Form {...formProps} wrapperCol={{ span: 14 }} layout="vertical">
        <Form.Item name="title" label="Name">
          <Input />
        </Form.Item>
        <Form.Item label="User access">
          <UserPermissions />
        </Form.Item>
        <Form.Item label="Group access">
          <GroupPermissions />
        </Form.Item>
      </Form>
    </Edit>
  );
}

function projectToOption(d: Project) {
  return (
    <Select.Option key={d.name} value={d.name}>
      {d.title}
    </Select.Option>
  );
}

interface ProjectSelectProps extends SelectProps<string> {
  collapsed?: boolean;
}

export function ProjectSelect({ collapsed, ...props }: ProjectSelectProps) {
  const { list } = useNavigation();
  const [searchValue, setSearchValue] = useState<string>("");
  const [debouncedSearchValue] = useDebounce(searchValue, 300);
  const { data: searchResults } = useList<Project>({
    resource: "projects",
    queryOptions: {
      enabled: !collapsed,
    },
    config: {
      filters: [
        {
          field: "title",
          operator: "eq",
          value: `"${debouncedSearchValue}*"`,
        },
      ],
    },
  });
  const { data: valueData } = useOne<Project>({
    resource: "",
    id: props.value!,
    queryOptions: {
      enabled: !collapsed && !!props?.value,
    },
  });

  let options = searchResults?.data.map(projectToOption);
  if (valueData?.data) {
    options = options ? [...options] : [];
  }

  options = options?.filter(
    (o, i) => options?.findIndex((p) => o.key === p.key) === i
  );

  const button = (
    <Button
      style={{ flex: "0 0 auto" }}
      icon={<SettingOutlined />}
      title="Project settings"
      onClick={() => list("projects")}
    />
  );

  return (
    <div
      style={{
        marginLeft: 24,
        marginRight: 24,
        marginBottom: 10,
        display: "flex",
      }}
    >
      {collapsed ? (
        button
      ) : (
        <Input.Group compact style={{ display: "flex", flex: "1" }}>
          <Select<string>
            style={{ flex: "1", minWidth: "0" }}
            showSearch
            defaultActiveFirstOption={false}
            filterOption={false}
            placeholder={"Select project"}
            onSearch={(v) => setSearchValue(v)}
            notFoundContent={null}
            {...props}
          >
            {options}
          </Select>
          {button}
        </Input.Group>
      )}
    </div>
  );
}

export interface ProjectProp {
  project: string;
}

export const ProjectPage: React.FC<{
  children: (project: string) => ReactElement;
}> = ({ children, ...props }) => {
  const [project] = useProject();
  if (!project) {
    return (
      <Result
        status="info"
        title="Select a project"
        extra={
          <Space direction="vertical" size="large">
            <Space>
              <Text>Please select a project to access this page.</Text>
            </Space>
          </Space>
        }
      />
    );
  }
  if (children) {
    return React.cloneElement(children(project), props);
  }
  return null;
};

function userToOption(u: User) {
  return (
    <Select.Option key={u.name} value={u.name}>
      {u.emails[0].email}
    </Select.Option>
  );
}

export function UserEmailInput(props: SelectProps<string>) {
  const [searchValue, setSearchValue] = useState<string>("");
  const [debouncedSearchValue] = useDebounce(searchValue, 300);
  const { data: searchResults } = useList<User>({
    resource: "users",
    config: {
      filters: [
        {
          field: "email",
          operator: "eq",
          value: `"${debouncedSearchValue}*"`,
        },
      ],
    },
  });
  const { value: propValue, onChange, ...rest } = props;

  let options = searchResults?.data.map(userToOption);

  return (
    <Select
      showSearch
      value={propValue}
      style={{ width: 200 }}
      onSearch={(v) => setSearchValue(v)}
      placeholder="User"
      defaultValue={propValue}
      onChange={(v, o) => onChange?.(v ?? "", o)}
      {...rest}
    >
      {options}
    </Select>
  );
}

function groupToOption(group: Group) {
  return (
    <Select.Option key={group.name} value={group.name}>
      {group.title}
    </Select.Option>
  );
}

export function GroupInput(props: SelectProps<string>) {
  const [searchValue, setSearchValue] = useState<string>("");
  const [debouncedSearchValue] = useDebounce(searchValue, 300);
  const { data: searchResults } = useList<Group>({
    resource: "groups",
    config: {
      filters: [
        {
          field: "title",
          operator: "eq",
          value: `"${debouncedSearchValue}*"`,
        },
      ],
    },
  });
  const { value: propValue, onChange, ...rest } = props;

  let options = searchResults?.data.map(groupToOption);

  return (
    <Select
      showSearch
      value={propValue}
      style={{ width: 200 }}
      onSearch={(v) => setSearchValue(v)}
      placeholder="User"
      defaultValue={propValue}
      onChange={(v, o) => onChange?.(v ?? "", o)}
      {...rest}
    >
      {options}
    </Select>
  );
}

const roles = [
  { value: "reader", key: "reader" },
  { value: "writer", key: "writer" },
  { value: "signer", key: "signer" },
];

export function UserPermissions() {
  return (
    <Form.List name="userPermissions">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space
              key={key}
              style={{ display: "flex", flex: "1 1 auto", marginBottom: 8 }}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, "user"]}
                rules={[{ required: true, message: "Missing email" }]}
              >
                <UserEmailInput placeholder="Email" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, "roles"]}
                rules={[{ required: true, message: "Missing roles" }]}
              >
                <Select
                  mode="multiple"
                  style={{ minWidth: "80pt", width: "100%" }}
                  placeholder="select roles"
                  optionLabelProp="label"
                  options={roles}
                ></Select>
              </Form.Item>
              <MinusCircleOutlined onClick={() => remove(name)} />
            </Space>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Add user
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
}

export function GroupPermissions() {
  return (
    <Form.List name="groupPermissions">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space
              key={key}
              style={{ display: "flex", flex: "1 1 auto", marginBottom: 8 }}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, "group"]}
                rules={[{ required: true, message: "Missing group" }]}
              >
                <GroupInput placeholder="Group" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, "roles"]}
                rules={[{ required: true, message: "Missing roles" }]}
              >
                <Select
                  mode="multiple"
                  style={{ minWidth: "80pt", width: "100%" }}
                  placeholder="select roles"
                  optionLabelProp="label"
                  options={roles}
                ></Select>
              </Form.Item>
              <MinusCircleOutlined onClick={() => remove(name)} />
            </Space>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Add group
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
}

export function ProjectShow() {
  const { queryResult } = useShow<Project>();
  const record = queryResult.data?.data;
  const { list } = useNavigation();

  const { data: usersData } = useMany<User>({
    resource: "users",
    ids: record?.userPermissions.map((value) => value.user).flat()!,
    queryOptions: {
      enabled: !!record?.userPermissions,
    },
  });
  const usrArr = usersData ? usersData.data! : [];
  const usrDict = usersData
    ? Object.fromEntries(usrArr.map((t) => [t.name, t.emails[0].email]))
    : {};

  type TablePermission = {
    type: string;
    roles: string;
  };
  const userPermissions = record?.userPermissions.map(
    (usr): TablePermission => ({
      type: usrDict[usr.user],
      roles: usr.roles.join(", "),
    })
  );

  const { data: groupsData } = useMany<Group>({
    resource: "groups",
    ids: record?.groupPermissions.map((value) => value.group).flat()!,
    queryOptions: {
      enabled: !!record?.groupPermissions,
    },
  });
  const groupArr = groupsData ? groupsData.data! : [];
  const grpDict = usersData
    ? Object.fromEntries(groupArr.map((t) => [t.name, t.title]))
    : {};
  const groupPermissions = record?.groupPermissions.map(
    (grp): TablePermission => ({
      type: grpDict[grp.group],
      roles: grp.roles.join(", "),
    })
  );

  type PermColumn = {
    title: string;
    dataIndex: string;
    key: string;
  };

  const userColumns: PermColumn[] = [
    {
      title: "User",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
    },
  ];

  const groupColumns: PermColumn[] = [
    {
      title: "Group",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
    },
  ];

  return (
    <>
      <Show
        isLoading={queryResult?.isLoading}
        canDelete
        actionButtons={
          <>
            <DeleteButton
              recordItemId={record?.name}
              onSuccess={() => list("users")}
            />
          </>
        }
      >
        <Space direction="vertical" size="large">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col>
              <Title level={5}>Title</Title>
              <Text style={{ whiteSpace: "nowrap" }}>
                {queryResult.data?.data?.title}
              </Text>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col>
              <Title level={5}>User Permissions</Title>

              <Table
                dataSource={userPermissions}
                columns={userColumns}
                pagination={false}
              ></Table>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col>
              <Title level={5}>Group permissions</Title>
              <Table
                dataSource={groupPermissions}
                columns={groupColumns}
                pagination={false}
              ></Table>
            </Col>
          </Row>
        </Space>
      </Show>
    </>
  );
}
