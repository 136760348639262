import { useApiUrl } from "@pankod/refine-core";
import { Button, ButtonProps } from "@pankod/refine-antd";
import { DownloadFileResponse } from "interfaces/dirac/connect/depot/v1alpha1/file";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { useCallback, useState } from "react";
import { httpClient } from "providers/dataProvider";

type DownloadButtonProps = ButtonProps & { file?: string; compact?: boolean };

export function DownloadButton(props: DownloadButtonProps) {
  const { file, children, compact, ...rest } = props;
  const apiUrl = useApiUrl();
  const [loading, setLoading] = useState(false);

  const onClick = useCallback(async () => {
    setLoading(true);
    const data = await httpClient.get<DownloadFileResponse>(
      `${apiUrl}/${file}:download`
    );
    window.location.href = data?.data.url;
    setLoading(false);
  }, [apiUrl, file]);

  return (
    <Button
      onClick={onClick}
      icon={<CloudDownloadOutlined />}
      loading={loading}
      download
      {...rest}
    >
      {!compact && (children ?? "Download")}
    </Button>
  );
}
