import { useList } from "@pankod/refine-core";
import { Transfer } from "@pankod/refine-antd";
import { File } from "interfaces/dirac/connect/depot/v1alpha1/file";
import { useProject } from "context/projectState";

export function FilePicker({ value, onChange }: FilePickerProps) {
  const [project] = useProject();
  const { data } = useList<File>({
    resource: "files",
    metaData: { parent: project },
    config: {
      pagination: {
        pageSize: 1000,
      },
    },
    queryOptions: {
      enabled: !!project,
    },
  });

  return (
    <Transfer
      style={{ height: "35em" }}
      targetKeys={value}
      onChange={onChange}
      showSearch
      pagination
      rowKey={(item) => item.name}
      render={(item) => item?.filename}
      dataSource={data?.data}
    />
  );
}

export interface FilePickerProps {
  value?: string[];
  onChange?(value?: string[]): void;
}
