import { useApiUrl, useCustomMutation, useDelete } from "@pankod/refine-core";
import {
  Button,
  Space,
  Table,
  Result,
  Typography,
  Popconfirm,
  AntdBreadcrumb,
} from "@pankod/refine-antd";
import { DeleteOutlined } from "@ant-design/icons";
import { DownloadButton } from "components/file/downloadButton";
import { EditLink } from "components/actionLink";
import { ReactNode } from "react";
import { Entry } from "interfaces/dirac/connect/depot/v1alpha1/file";
import querystring from "query-string";
import { DateTimeField } from "components/dateTimeField";
import { FileBrowserProps } from "./list";

export function FileBrowser({
  files,
  project,
  pagination,
  dirname,
  setDirname,
}: FileBrowserProps) {
  const apiUrl = useApiUrl();
  const { data, isLoading, refetch } = files;
  const { mutateAsync } = useCustomMutation();
  const { mutateAsync: deleteAsync } = useDelete();

  const renderNameColumn = (_: unknown, entry: Entry) =>
    entry.file ? (
      <Space>
        <EditLink resourceName="files" recordItemId={entry.file.name}>
          {entry.name}
        </EditLink>
        <DownloadButton compact size="small" file={entry.file.name} />
      </Space>
    ) : (
      <Typography.Link onClick={() => setDirname(entry.fullName)}>
        <strong>{entry.name.length > 0 ? entry.name : <em>???</em>}/</strong>
      </Typography.Link>
    );
  const renderUploadedColumn = (_: unknown, entry: Entry) =>
    entry.file ? <DateTimeField value={entry.file.createdAt} /> : <>&mdash;</>;
  const renderActionsColumn = (_: unknown, entry: Entry) =>
    entry.file ? (
      <Space>
        <Popconfirm
          title={"Delete file?"}
          onConfirm={async () => {
            await deleteAsync({ resource: "files", id: entry.file?.name! });
            await refetch();
          }}
        >
          <Button icon={<DeleteOutlined />} size="small" danger>
            Delete
          </Button>
        </Popconfirm>
      </Space>
    ) : (
      entry.name !== ".." && (
        <Popconfirm
          title={"Delete directory?"}
          onConfirm={async () => {
            const query = querystring.stringify({ name: entry.fullName });

            await mutateAsync({
              url: `${apiUrl}/${project}/files:dir?${query}`,
              method: "delete",
              values: {},
            });
            await refetch();
          }}
        >
          <Button size="small" danger icon={<DeleteOutlined />}>
            Delete
          </Button>
        </Popconfirm>
      )
    );

  // TODO: Add support for multi-operations. Use this to set properties.
  // const getCheckboxProps = (entry: Entry) => {
  //     return entry.file ? { } : {
  //         style: { display: "none" },
  //         disabled: true,
  //     };
  // };
  let links: ReactNode[] = [];

  if (dirname.length) {
    const parts = dirname.split("/");
    links = parts.map((name, i) => {
      const fullPath = parts.slice(0, i + 1).join("/");
      return (
        <AntdBreadcrumb.Item>
          <Typography.Link key={fullPath} onClick={() => setDirname(fullPath)}>
            {name}
          </Typography.Link>
        </AntdBreadcrumb.Item>
      );
    });
  }
  links.unshift(
    <AntdBreadcrumb.Item>
      <Typography.Link key={"/"} onClick={() => setDirname("")}>
        <em>Root</em>
      </Typography.Link>
    </AntdBreadcrumb.Item>
  );

  return (
    <>
      <AntdBreadcrumb style={{ margin: "1em 0" }}>{links}</AntdBreadcrumb>
      <Table<Entry>
        pagination={pagination}
        loading={isLoading}
        rowKey={(entry) => entry.fullName}
        dataSource={data?.data.entries}
      >
        <Table.Column<Entry> title="Name" render={renderNameColumn} />
        <Table.Column<Entry> title="Uploaded" render={renderUploadedColumn} />
        <Table.Column<Entry> title="Actions" render={renderActionsColumn} />
      </Table>
    </>
  );
}
