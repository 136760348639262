import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { stringify } from "query-string";
import {
  CrudFilters,
  CrudOperators,
  DataProvider,
  HttpError,
} from "@pankod/refine-core";

const axiosInstance = axios.create();

function camelize(str: string): string {
  return str
    .replace(/(?:^\w|[A-Z]|-|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/[-\s]+/g, "");
}

export let apiUrl: URL;

if (process.env.REACT_APP_API_URL) {
  apiUrl = new URL(process.env.REACT_APP_API_URL);
} else {
  // Derive API URL from origin domain by default
  apiUrl = new URL("/v1alpha1", document.location.origin);
  apiUrl.host = apiUrl.host.replace(/^[^.]*\./, "fm.");
}

axiosInstance.interceptors.request.use((request) => {
  const tokenJSON = localStorage.getItem("token");
  if (tokenJSON) {
    const token = JSON.parse(tokenJSON);
    if (token.idToken) {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${token.idToken}`,
      };
    }
  }
  return request;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);

const mapOperator = (operator: CrudOperators): string => {
  switch (operator) {
    case "eq":
      return "=";
    case "ne":
      return "!=";
    case "gt":
      return ">";
    case "lt":
      return "<";
    case "gte":
      return ">=";
    case "lte":
      return "<=";
    case "in":
      return "in";
    case "contains":
      return ":";
  }

  throw new Error("unsupported operator " + operator);
};

const generateFilter = (filters?: CrudFilters) => {
  const queryFilters: string[] = [];
  if (filters) {
    filters.forEach((f) => {
      if (f.operator !== "or") {
        if (f.operator === "in") {
          const orList = f.value
            .map((v: string) => `${f.field} = ${v}`)
            .join(" OR ");
          queryFilters.push(orList);
        } else {
          const mappedOperator = mapOperator(f.operator);
          queryFilters.push(`${f.field} ${mappedOperator} ${f.value}`);
        }
      }
    });
  }
  return queryFilters.join(" AND ");
};

interface UrlParams {
  resource: string;
  parent?: string;
  name?: string | number;
}

export const httpClient = axiosInstance;

class FADataSource implements DataProvider {
  constructor(private httpClient: AxiosInstance = axiosInstance) {}

  getApiUrl: DataProvider["getApiUrl"] = () => apiUrl.toString();

  private getUrl({ name, parent, resource }: UrlParams): string {
    let url = apiUrl.toString();
    if (name) {
      url += "/" + name;
    } else {
      if (parent) {
        url += "/" + parent;
      }
      url += "/" + resource;
    }
    return url;
  }

  getList: DataProvider["getList"] = async ({
    resource,
    pagination,
    filters,
    metaData,
  }) => {
    const urlParams: UrlParams = { resource };
    urlParams.parent = metaData?.["parent"];

    const url = this.getUrl(urlParams);

    const current = pagination?.current || 1;
    const pageSize = pagination?.pageSize || 10;

    const queryFilters = generateFilter(filters);

    const query: {
      skip: number;
      pageSize: number;
      filter?: string;
    } = {
      skip: (current - 1) * pageSize,
      pageSize: pageSize,
      filter: queryFilters,
    };

    const { data } = await this.httpClient.get(`${url}?${stringify(query)}`);

    const listProp = camelize(resource);

    const l = data as any;
    return {
      data: l[listProp],
      total: Math.ceil(l.totalSize * 1.1), // make estimate bigger by 10% to prevent missing pages
    };
  };

  getMany: DataProvider["getMany"] = async ({ resource, ids, metaData }) => {
    const promises = ids.map(
      async (id) => (await this.getOne({ resource, id, metaData })).data
    );
    const l = (await Promise.all(promises)) as any;
    return {
      data: l,
      total: l.length,
    };
  };

  create: DataProvider["create"] = async ({ resource, variables }) => {
    const urlParams: UrlParams = { resource };
    if ((variables as any).parent) {
      urlParams.parent = (variables as any).parent;
      delete (variables as any).parent;
    }
    const url = this.getUrl(urlParams);
    const { data: responseData } = await this.httpClient.post(url, variables);
    return {
      data: responseData as any,
    };
  };

  createMany: DataProvider["createMany"] = async ({
    resource,
    metaData,
    variables,
  }) => {
    const response = await Promise.all(
      variables.map((param) =>
        this.create({ resource, metaData, variables: param })
      )
    );
    return { data: response as any };
  };

  update: DataProvider["update"] = async ({ resource, id, variables }) => {
    const urlParams: UrlParams = { resource, name: id };
    const url = this.getUrl(urlParams);
    const { data } = await this.httpClient.patch(url, variables);
    return { data: data as any };
  };

  updateMany: DataProvider["updateMany"] = async ({
    resource,
    ids,
    variables,
  }) => {
    const response = await Promise.all(
      ids.map(async (id) => this.update({ resource, id, variables }))
    );
    return { data: response as any };
  };

  getOne: DataProvider["getOne"] = async ({ resource, id }) => {
    const url = this.getUrl({ resource, name: id });
    const { data } = await this.httpClient.get(url);
    return { data };
  };

  deleteOne: DataProvider["deleteOne"] = async ({ resource, id }) => {
    const url = this.getUrl({ resource, name: id });
    await this.httpClient.delete(url);
    return {} as any;
  };

  deleteMany: DataProvider["deleteMany"] = async ({
    resource,
    ids,
    metaData,
  }) => {
    await Promise.all(
      ids.map((id) => this.deleteOne({ resource, id, metaData }))
    );
    return {} as any;
  };

  custom: DataProvider["custom"] = async ({
    url,
    method,
    filters,
    sort,
    payload,
    query,
    headers,
  }) => {
    let requestUrl = url;
    if (query) {
      requestUrl = `${requestUrl}?${stringify(query)}`;
    }
    const config: AxiosRequestConfig = {};
    if (headers) {
      config.headers = headers;
    }
    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await this.httpClient[method](url, payload, config);
        break;
      case "delete":
        axiosResponse = await this.httpClient.delete(url, config);
        break;
      default:
        axiosResponse = await this.httpClient.get(requestUrl, config);
        break;
    }

    const { data } = axiosResponse;
    return { data };
  };
}

export default FADataSource;
