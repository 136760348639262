import React from "react";
import {
  ResourceRouterParams,
  useNavigation,
  useRouterContext,
} from "@pankod/refine-core";
import { Typography } from "@pankod/refine-antd";

interface LinkProps extends React.ComponentProps<typeof Typography.Link> {
  resourceName?: string;
  recordItemId?: string;
}

interface ActionLinkProps extends LinkProps {
  action: "edit" | "show";
}

export const EditLink = (props: LinkProps) =>
  ActionLink({ action: "edit", ...props });

export const ShowLink = (props: LinkProps) =>
  ActionLink({ action: "show", ...props });

export const ActionLink: React.FC<ActionLinkProps> = ({
  resourceName: propResourceName,
  recordItemId,
  children,
  action,
  ...rest
}) => {
  const { useParams } = useRouterContext();
  const { resource: routeResourceName, id: idFromRoute } =
    useParams<ResourceRouterParams>();
  const { edit, show } = useNavigation();

  const resourceName = propResourceName ?? routeResourceName;
  const id = recordItemId ?? idFromRoute;

  return (
    <Typography.Link
      href={`/${resourceName}/${action}/${id}`}
      onClick={(e) => {
        e.preventDefault();
        if (id) {
          if (action === "show") {
            show(resourceName, id);
          } else {
            edit(resourceName, id);
          }
        }
      }}
      {...rest}
    >
      {children ?? id}
    </Typography.Link>
  );
};
