import { useApiUrl, useCustom } from "@pankod/refine-core";
import { Card, Typography, PageHeader, Spin } from "@pankod/refine-antd";
import { Config } from "interfaces/dirac/connect/depot/v1alpha1/config";
import { useProfile } from "hooks/data/profile";

const { Title, Text } = Typography;

export function ShowAbout() {
  const apiUrl = useApiUrl();
  const { isLoading, data } = useCustom<Config>({
    method: "get",
    url: `${apiUrl}/config`,
  });
  const profile = useProfile();

  const record = data?.data;

  return (
    <>
      <PageHeader ghost={false} title="About Depot" />

      <Spin spinning={isLoading}>
        <Card bordered={false}>
          <Title level={5}>Frontend</Title>
          <Text>
            {process.env.REACT_APP_VERSION &&
              `Version ${process.env.REACT_APP_VERSION}`}{" "}
            {process.env.NODE_ENV && `(${process.env.NODE_ENV})`}
          </Text>

          <Title level={5}>Server</Title>
          <Text>
            Version {record?.version ?? <>&mdash;</>}{" "}
            {record?.versionSum && <>({record.versionSum})</>}
          </Text>

          <Title level={5}>User</Title>
          <Text>
            <pre>
              <code>{JSON.stringify(profile, null, 4)}</code>
            </pre>
          </Text>
        </Card>
      </Spin>
    </>
  );
}
