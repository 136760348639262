import { useApiUrl, useCustom } from "@pankod/refine-core";
import { Config } from "interfaces/dirac/connect/depot/v1alpha1/config";

export function useConfig() {
  const apiUrl = useApiUrl();

  return useCustom<Config>({
    url: `${apiUrl}/config`,
    method: "get",
    queryOptions: {
      staleTime: Infinity,
    },
  });
}
