import { ReactNode, useState } from "react";
import { Popover } from "@pankod/refine-antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

export default function Help({
  content,
  title,
}: {
  content: ReactNode;
  title: string;
}) {
  const [visible, setVisible] = useState(false);
  return (
    <Popover title={title} visible={visible} content={content}>
      &nbsp;
      <QuestionCircleOutlined onClick={() => setVisible(!visible)} />
    </Popover>
  );
}
