import {
  Create,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Grid,
  Input,
  SaveButton,
} from "@pankod/refine-antd";
import React from "react";
import { UserPicker } from "components/user/userPicker";

interface GroupCreateProps {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: React.ComponentProps<typeof SaveButton>;
}
export const GroupCreate: React.FC<GroupCreateProps> = ({
  drawerProps,
  saveButtonProps,
  formProps,
}) => {
  const breakpoint = Grid.useBreakpoint();
  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
    >
      <Create
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{ style: { background: "transparent" } }}
      >
        <Form {...formProps} layout="vertical">
          <Form.Item name="title" label="Name">
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input />
          </Form.Item>
          <Form.Item name="users" label="Users">
            <UserPicker />
          </Form.Item>
        </Form>
      </Create>
    </Drawer>
  );
};
