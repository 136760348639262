import { useList } from "@pankod/refine-core";
import { Transfer } from "@pankod/refine-antd";
import { Project } from "interfaces/dirac/connect/depot/v1alpha1/project";

interface ProjectPickerProps {
  value?: string[];

  onChange?(value?: string[]): void;
}

export function ProjectPicker({ value, onChange }: ProjectPickerProps) {
  const { data } = useList<Project>({
    resource: "projects",
    metaData: {},
    config: {
      pagination: {
        pageSize: 1000,
      },
    },
    queryOptions: {},
  });

  return (
    <Transfer
      style={{ height: "35em" }}
      targetKeys={value}
      onChange={onChange}
      showSearch
      pagination
      rowKey={(item) => item.name}
      render={(item) => item?.title}
      dataSource={data?.data}
    />
  );
}
