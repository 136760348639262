import {
  useApiUrl,
  useNavigation,
  useNotification,
  useOne,
  useShow,
} from "@pankod/refine-core";
import {
  DeleteButton,
  Show,
  Space,
  Row,
  Col,
  Typography,
} from "@pankod/refine-antd";
import { DownloadButton } from "components/file/downloadButton";
import { useEffect, useState } from "react";
import { File } from "interfaces/dirac/connect/depot/v1alpha1/file";
import { Labels } from "components/label";
import { SignModal } from "components/file/signModal";
import { AxiosResponse } from "axios";
import { Operation } from "interfaces/dirac/connect/depot/v1alpha1/operation";
import { httpClient } from "providers/dataProvider";
import { DarAnalysisCard } from "./list";

const { Title, Text } = Typography;

export function FileShow() {
  const { queryResult, showId } = useShow<File>();
  const { data } = queryResult;
  const record = queryResult.data?.data;
  const apiUrl = useApiUrl();
  const { list } = useNavigation();
  const [signModalVisible, setSignModalVisible] = useState(false);
  const { show } = useNavigation();
  const [analysisOperation, setAnalysisOperation] = useState<string | null>(
    null
  );
  const { isLoading: isAnalysisOperationLoading, data: analysisData } =
    useOne<Operation>({
      resource: "operations",
      id: analysisOperation!,
      queryOptions: {
        refetchInterval: 1000,
        enabled: !!analysisOperation,
      },
    });
  const { open: openNotification } = useNotification();

  const isAnalysisDone =
    !!analysisOperation && analysisData?.data?.done === true;
  const isAnalysisLoading =
    !!analysisOperation &&
    (isAnalysisOperationLoading || analysisData?.data?.done === false);

  // Refetch file when analysis is fetched.
  useEffect(() => {
    if (isAnalysisDone) {
      queryResult?.refetch();
      setAnalysisOperation(null);

      openNotification?.({
        type: "success",
        description: "Analysis done",
        message: "The file was analyzed successfully",
      });
    }
  }, [queryResult, isAnalysisDone, setAnalysisOperation, openNotification]);

  return (
    <>
      <Show
        canDelete
        isLoading={queryResult?.isLoading}
        actionButtons={
          <>
            <DownloadButton file={queryResult.data?.data?.name} />
            <DeleteButton
              recordItemId={queryResult.data?.data?.name}
              onSuccess={() => list("files")}
            />
          </>
        }
      >
        <Space direction="vertical" size="large">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col sm={20}>
              <Title level={5}>Name</Title>
              <Text style={{ whiteSpace: "nowrap" }}>
                {queryResult.data?.data?.filename}
              </Text>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col sm={20}>
              <Title level={5}>Type</Title>
              <Text style={{ whiteSpace: "nowrap" }}>
                {queryResult.data?.data?.type}
              </Text>
            </Col>
          </Row>
          {record?.labels && (
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col sm={150}>
                <Title level={5}>Labels</Title>
                <Labels value={data?.data.labels} />
              </Col>
            </Row>
          )}
        </Space>
      </Show>

      {record?.type === "dar" && (
        <DarAnalysisCard
          file={record}
          isAnalysisLoading={isAnalysisLoading}
          onAnalyze={async () => {
            const response = await httpClient.post<
              any,
              AxiosResponse<Operation>
            >(`${apiUrl}/${showId}:process`, {
              type: "analyze",
            });
            setAnalysisOperation(response?.data?.name);
          }}
        />
      )}

      <SignModal
        onChange={(name) => {
          setSignModalVisible(false);
          openNotification?.({
            type: "success",
            description: "Signed",
            message: "A signed copy of the file was successfully created",
          });
          setTimeout(() => show("files", name, "replace"), 0);
        }}
        onError={(err) => {
          setSignModalVisible(false);
          openNotification?.({
            type: "error",
            description: `Error ${err.code}`,
            message: err.message,
          });
        }}
        onCancel={() => setSignModalVisible(false)}
        file={queryResult?.data?.data.name}
        signModalVisible={signModalVisible}
      />
    </>
  );
}
