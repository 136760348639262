import { Access } from "interfaces/dirac/connect/depot/v1alpha1/access";
import { apiUrl, httpClient } from "./dataProvider";
import { AccessControlProvider } from "@pankod/refine-core";

const accessProvider: AccessControlProvider = {
  can: async ({ resource, action }) => {
    const userAccess = await httpClient.get<Access>(apiUrl + "/access", {
      params: { resource, action },
    });
    return {
      can: userAccess.data.access,
    };
  },
};

export default accessProvider;
