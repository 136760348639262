import { IRouterProvider } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";

const wrappedRouterProvider: IRouterProvider = {
  ...routerProvider,

  useParams(): any {
    const ret = routerProvider.useParams();
    ret.resource = (ret.resource as string)?.split("/", 1)[0];

    return ret;
  },
};

export default wrappedRouterProvider;
