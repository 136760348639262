import { useApiUrl, useCustom } from "@pankod/refine-core";
import { UserInfo } from "interfaces/dirac/connect/depot/v1alpha1/user";

export function useUserInfo() {
  const apiUrl = useApiUrl();

  return useCustom<UserInfo>({
    url: `${apiUrl}/me`,
    method: "get",
    queryOptions: {
      staleTime: Infinity,
    },
  });
}
