import { AuthProvider } from "@pankod/refine-core";
import md5 from "md5";
import { stringify } from "query-string";
import { UserInfo } from "interfaces/dirac/connect/depot/v1alpha1/user";
import {
  AuthorizationNotifier,
  AuthorizationRequest,
  AuthorizationServiceConfiguration,
  FetchRequestor,
  RedirectRequestHandler,
  StringMap,
} from "@openid/appauth";
import { apiUrl, httpClient } from "./dataProvider";
import { notificationProvider } from "@pankod/refine-antd";
import { Config } from "interfaces/dirac/connect/depot/v1alpha1/config";

const serverConfiguration = httpClient.get<Config>(apiUrl + "/config");
const configuration = (async () =>
  await AuthorizationServiceConfiguration.fetchFromIssuer(
    (
      await serverConfiguration
    ).data.authIssuer,
    new FetchRequestor()
  ))();

const notifier = new AuthorizationNotifier();
const authorizationHandler = new RedirectRequestHandler();

authorizationHandler.setAuthorizationNotifier(notifier);

interface TokenState {
  idToken: string;
  expires?: number;
}

let hasError = false;

notifier.setAuthorizationListener(async (request, response, error) => {
  if (error) {
    notificationProvider.open({
      type: "error",
      message: "Authentication failed",
      description: `Provider returned error: ${error} - reload page to try again`,
    });
    hasError = true;
    return;
  }

  const q = authorizationHandler.utils.parse(document.location, true);
  const token: TokenState = {
    expires: Date.now() + 2700000, // 45 minutes. Gives 15 minutes of buffer, tokens are valid for 1 hour. TODO: figure out from token (server-side?)
    idToken: q["id_token"],
  };
  localStorage.setItem("token", JSON.stringify(token));
});

const complete = authorizationHandler.completeAuthorizationRequestIfPossible();

async function authorize(hidden = false) {
  const extras: StringMap = { nonce: Math.random().toString(36).slice(2) };
  if (hidden) {
    extras.prompt = "none";
  }
  const request = new AuthorizationRequest({
    client_id: (await serverConfiguration).data.authClientId,
    redirect_uri: document.location.origin,
    scope: "openid profile",
    response_type: "id_token",
    state: undefined,
    extras: extras,
  });

  authorizationHandler.performAuthorizationRequest(
    await configuration,
    request
  );
}

let userInfo: any | undefined;

const authProvider: AuthProvider = {
  async checkError(): Promise<void> {},
  async getPermissions(): Promise<any> {
    return ["admin"];
  },
  async getUserIdentity(): Promise<any> {
    if (!userInfo) {
      const userInfoData = (
        await httpClient.get<UserInfo>(process.env.REACT_APP_API_URL + "/me")
      ).data;
      userInfo = {
        name: userInfoData.email,
        avatar: `https://secure.gravatar.com/avatar/${md5(
          userInfoData.email.toLowerCase().trim()
        )}?${stringify({
          size: 150,
          default: "identicon",
          rating: "g",
        })}`,
      };
    }
    return userInfo;
  },
  async login(): Promise<any> {},
  async logout(): Promise<any> {},
  async checkAuth(): Promise<any> {
    await complete;

    if (hasError) {
      // Prevent error loop.
      return;
    }

    const tokenJSON = localStorage.getItem("token");
    if (tokenJSON) {
      const token: TokenState = JSON.parse(tokenJSON);
      const expiresIn = (token.expires ?? 0) - Date.now();

      if (expiresIn < 0) {
        authorize(true);
      }
    } else {
      authorize();
    }
  },
};

export default authProvider;
