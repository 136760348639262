import { createContext, useContext, FC, PropsWithChildren } from "react";
import { useLocalStorage } from "hooks/localStorage";

const ProjectStateContext = createContext<
  readonly [string, (value: string) => void]
>(["", () => {}]);

export const ProjectProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useLocalStorage<string>("project");
  return (
    <ProjectStateContext.Provider value={value}>
      {children}
    </ProjectStateContext.Provider>
  );
};

export const useProject = () => {
  const context = useContext(ProjectStateContext);
  if (!context) {
    throw new Error("useProject must be used within a <ProjectProvider/>");
  }
  return context;
};
