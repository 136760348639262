import { useCreate } from "@pankod/refine-core";
import { UploadFile, UploadProps } from "@pankod/refine-antd";
import { Upload } from "interfaces/dirac/connect/depot/v1alpha1/upload";
import { useCallback } from "react";
import { useConfig } from "./config";
import { useUserInfo } from "./userInfo";

interface DepotUploadProps {
  onUploaded?(file: UploadFile): void;
  uploadProps?: UploadProps;
}

// Hook for uploading one or many files to Depot using an Antd <Upload/>
// component.
//
// The returned uploadProps can be spread out as props for the <Upload/>.
// Disabled should be passed to the button element.
export function useDepotUpload({ onUploaded, uploadProps }: DepotUploadProps) {
  const { data: userInfo, isLoading: isUserInfoLoading } = useUserInfo();
  const { data: config, isLoading: isConfigLoading } = useConfig();
  const { mutateAsync: createUpload, isLoading: isUploadLoading } =
    useCreate<Upload>();
  //const [fileList, setFileList] = useState<UploadFile[]>([]);

  const block = isUserInfoLoading || isConfigLoading;
  const isLoading = isUserInfoLoading || isUploadLoading || isConfigLoading;

  // This callback is used by <Upload/> to get the POST fields for every file
  // upload.
  const dataCallback = useCallback(
    async (f: UploadFile) => {
      const data = await createUpload({
        resource: "uploads",
        values: { parent: userInfo?.data.name },
      });
      f.uid = data.data.name;
      return data.data.postData;
    },
    [createUpload, userInfo]
  );

  // This callback is used for every state change for every uploaded file.
  const onChangeCallback = useCallback(
    async (info: any) => {
      //setFileList(info.fileList);
      if (info.file.status === "done") {
        onUploaded?.(info.file);
      }
    },
    [onUploaded]
  );

  return {
    disabled: block,
    isLoading,
    uploadProps: {
      ...uploadProps,
      data: dataCallback,
      onChange: onChangeCallback,
      //fileList,

      action: config?.data?.uploadUrl,
    },
  };
}
