import { useList, useMany } from "@pankod/refine-core";
import { Select, SelectProps } from "@pankod/refine-antd";
import { useDebounce } from "use-debounce";
import { useState } from "react";
import { File } from "interfaces/dirac/connect/depot/v1alpha1/file";
import { useProject } from "context/projectState";
import { assetToOption } from "../../pages/file/list";

export function FileSelect(props: SelectProps<string[]>) {
  const [project] = useProject();
  const [searchValue, setSearchValue] = useState<string>("");
  const [debouncedSearchValue] = useDebounce(searchValue, 300);
  const { data: searchResults } = useList<File>({
    resource: "files",
    metaData: { parent: project },
    config: {
      filters: [
        {
          field: "filename",
          operator: "eq",
          value: `"${debouncedSearchValue}*"`,
        },
        {
          field: "filetype",
          operator: "eq",
          value: '"dar"',
        },
      ],
    },
    queryOptions: {
      enabled: !!project,
    },
  });

  const { data: valueData } = useMany<File>({
    resource: "files",
    ids: Array.isArray(props.value)
      ? props.value
      : props.value
      ? [props.value]
      : [],
    queryOptions: {
      enabled: !!props?.value?.length,
    },
  });

  let options = searchResults?.data.map(assetToOption);
  if (valueData) {
    const valueOptions = valueData.data?.map(assetToOption);
    options = options ? [...valueOptions, ...options] : valueOptions;
  }

  options = options?.filter(
    (o, i) => options?.findIndex((p) => o.key === p.key) === i
  );

  return (
    <Select<string[]>
      showSearch
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={(v) => setSearchValue(v)}
      notFoundContent={null}
      {...props}
    >
      {options}
    </Select>
  );
}
