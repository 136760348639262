import {
  Button,
  Checkbox,
  Create,
  DeleteButton,
  Edit,
  Show,
  Form,
  Input,
  List,
  Space,
  Table,
  Tag,
  useForm,
  useTable,
  Row,
  Col,
  Typography,
  EditButton,
} from "@pankod/refine-antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ShowLink } from "components/actionLink";
import { User } from "interfaces/dirac/connect/depot/v1alpha1/user";
import { IResourceItem, useNavigation, useShow } from "@pankod/refine-core";

export interface IRole {
  id: string;
  title: string;
}

const resourceProps: IResourceItem = {
  name: "users",
  icon: <UserOutlined />,
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  show: UserShow,
};

export default resourceProps;

export function UserList() {
  const { tableProps } = useTable<User>();

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="name"
          title="ID"
          render={(id) => <ShowLink recordItemId={id} />}
        />
        <Table.Column<User>
          title="Emails"
          dataIndex="emails"
          render={(_, user) => {
            return user.emails.map((email) => {
              return <Tag key={email.email}>{email.email}</Tag>;
            });
          }}
        />
        <Table.Column<User>
          title="Global roles"
          dataIndex="roles"
          render={(_, record) => {
            return record.roles.map((role) => {
              return <Tag key={role}>{role}</Tag>;
            });
          }}
        />
        <Table.Column<User>
          title="Actions"
          render={(_, record) => {
            return (
              <Space>
                <EditButton
                  size="small"
                  resourceName="users"
                  recordItemId={record.name}
                />
                <DeleteButton
                  size="small"
                  resourceName="users"
                  recordItemId={record.name}
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
}

export function UserCreate() {
  const { formProps, saveButtonProps } = useForm<User>();
  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} wrapperCol={{ span: 14 }} layout="vertical">
        <Form.Item label="Email addresses">
          <Form.List name="emails" initialValue={[{ email: "" }]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      flex: "1 1 auto",
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "email"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing email",
                          type: "email",
                        },
                      ]}
                    >
                      <Input type="email" placeholder="Email" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add email
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item label="Global roles" name="roles">
          <Checkbox.Group options={["admin", "reader", "developer"]} />
        </Form.Item>
      </Form>
    </Create>
  );
}

export function UserEdit() {
  const { formProps, saveButtonProps, queryResult } = useForm<User>({
    resource: "",
  });
  return (
    <Edit
      isLoading={queryResult?.isLoading}
      saveButtonProps={saveButtonProps}
      canDelete
    >
      <Form {...formProps} wrapperCol={{ span: 14 }} layout="vertical">
        <Form.Item label="Email addresses">
          <Form.List name="emails" key="id">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "email"]}
                      rules={[
                        {
                          required: true,
                          message: "Not a valid email.",
                          type: "email",
                        },
                      ]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add email
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item label="Global roles" name="roles">
          <Checkbox.Group options={["admin", "reader", "developer"]} />
        </Form.Item>
      </Form>
    </Edit>
  );
}

const { Title, Text } = Typography;

export function UserShow() {
  const { queryResult } = useShow<User>();
  const record = queryResult.data?.data;
  const { list } = useNavigation();

  return (
    <>
      <Show
        isLoading={queryResult?.isLoading}
        canDelete
        actionButtons={
          <>
            <DeleteButton
              recordItemId={record?.name}
              onSuccess={() => list("users")}
            />
          </>
        }
      >
        <Space direction="vertical" size="large">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col sm={50}>
              <Title level={5}>Emails</Title>
              <div>
                {record?.emails.map((item, index) => (
                  <Text key={item.email}>
                    {index > 0 && ", "}
                    {item.email}
                  </Text>
                ))}
              </div>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col sm={50}>
              <Title level={5}>Roles</Title>
              <div>
                {record?.roles.map((item, index) => (
                  <Text key={item}>
                    {index > 0 && ", "}
                    {item}
                  </Text>
                ))}
              </div>
            </Col>
          </Row>
        </Space>
      </Show>
    </>
  );
}
