import {
  CustomResponse,
  HttpError,
  IResourceItem,
  useApiUrl,
  useCustom,
  useCustomMutation,
  useOne,
} from "@pankod/refine-core";
import { QueryObserverResult } from "@tanstack/query-core";
import {
  Button,
  Card,
  List,
  AntdList,
  Select,
  Space,
  Statistic,
  Result,
  Typography,
  Row,
  Col,
  TablePaginationConfig,
} from "@pankod/refine-antd";
import {
  FileZipOutlined,
  FileSearchOutlined,
  FolderAddOutlined,
} from "@ant-design/icons";
import { useDebouncedCallback } from "use-debounce";
import { FileUpload } from "components/file/fileUpload";
import { EditLink } from "components/actionLink";
import { useEffect, useMemo, useState } from "react";
import {
  BrowseFilesResponse,
  File,
} from "interfaces/dirac/connect/depot/v1alpha1/file";
import humanFileSize from "fileSize";
import { Key } from "interfaces/dirac/connect/depot/v1alpha1/key";
import { ProjectPage, ProjectProp } from "pages/project/project";
import { FileEdit } from "./edit";
import { FileShow } from "./show";
import { FileBrowser } from "./browser";

const resourceProps: IResourceItem = {
  name: "files",
  icon: <FileZipOutlined />,
  list: FileList,
  edit: FileEdit,
  show: FileShow,
};

export default resourceProps;

function FileList() {
  return (
    <ProjectPage>
      {(project: string) => (
        <>
          <FileListInner project={project} />
        </>
      )}
    </ProjectPage>
  );
}

function FileListInner({ project }: ProjectProp) {
  const apiUrl = useApiUrl();
  const [dirname, setDirname] = useState("");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  const query = {
    dirname: dirname ?? "",
    skip: (page - 1) * pageSize,
    pageSize,
  };

  const files = useCustom<BrowseFilesResponse>({
    url: `${apiUrl}/${project}/files:browse`,
    method: "get",
    config: { query },
  });

  const pagination: TablePaginationConfig = {
    current: page,
    pageSize,
    total: files.data?.data?.totalSize,
    onChange(page, pageSize) {
      setPage(page);
      setPageSize(pageSize);
    },
  };

  const { mutateAsync: mutateDirectory } = useCustomMutation();
  const prefix = useMemo(
    () => dirname?.toString().replace(/^\/+|\/+$/g, "") || "",
    [dirname]
  );
  const refresh = useDebouncedCallback(() => {
    files.refetch();
  }, 500);

  function changeDir(dir: string) {
    setPage(1);
    setDirname(dir);
  }

  return (
    <List
      title={
        <>
          <Space size="large">
            <span>Files</span>
            <FileUpload prefix={prefix} onUploaded={refresh} />
            <Button
              icon={<FolderAddOutlined />}
              onClick={async () => {
                const name = window.prompt("Name of directory");
                if (name) {
                  const fullName = prefix.length ? `${prefix}/${name}` : name;
                  await mutateDirectory({
                    url: `${apiUrl}/${project}/files:dir`,
                    method: "post",
                    values: { name: fullName },
                  });
                  changeDir(fullName);
                }
              }}
            >
              Create directory
            </Button>
          </Space>
        </>
      }
      headerButtons={<Button onClick={refresh}>Refresh</Button>}
    >
      <FileBrowser
        pagination={pagination}
        dirname={dirname}
        setDirname={changeDir}
        project={project}
        files={files}
      />
    </List>
  );
}

export interface FileBrowserProps {
  files: QueryObserverResult<CustomResponse<BrowseFilesResponse>, HttpError>;
  project: string;
  dirname: string;
  setDirname(dirname: string): void;
  pagination: TablePaginationConfig;
}

type AssetTypeOption = {
  label: string;
  value: string;
  accept?: string;
};

const assetTypeOptions: AssetTypeOption[] = [
  {
    label: "XML filter",
    value: "xml",
    accept: ".xml,application/xml,text/xml",
  },
  { label: "DAR file", value: "dar" },
  { label: "Unknown", value: "unknown" },
];

const assetTypeByValue = Object.fromEntries(
  assetTypeOptions.map((t) => [t.value, t])
);

export function assetToOption(d: File) {
  return (
    <Select.Option key={d.name} value={d.name}>
      {d.filename} ({assetTypeByValue[d.type]?.label ?? d.type})
    </Select.Option>
  );
}

interface DarAnalysisCardProps {
  file: File;
  isAnalysisLoading?: boolean;
  onAnalyze?(): Promise<void> | void;
}

export function DarAnalysisCard({
  file,
  onAnalyze,
  isAnalysisLoading,
}: DarAnalysisCardProps) {
  const queryResult = useOne<Key>({
    resource: "keys",
    id: file?.analysis?.key_id ?? "",
    queryOptions: {
      enabled: !!file?.analysis?.key_id,
    },
  });
  return (
    <Card
      style={{ marginTop: "1em" }}
      title="DAR"
      bordered={false}
      actions={[
        <Button
          type="link"
          loading={isAnalysisLoading}
          onClick={onAnalyze}
          icon={<FileSearchOutlined />}
        >
          {file?.analysis?.files ? "Re-analyze" : "Analyze"}
        </Button>,
      ]}
    >
      {file?.analysis?.files ? (
        <Space size="large" direction="vertical" style={{ width: "100%" }}>
          <Row>
            <Col xs={8} sm={5}>
              <Statistic
                title="Size"
                value={file?.analysis?.size}
                formatter={(v) => <>{humanFileSize(v as number)}</>}
              />
            </Col>
            <Col xs={8} sm={5}>
              <Statistic title="Files" value={file?.analysis?.files?.length} />
            </Col>
            <Col xs={8} sm={5}>
              <Statistic
                title="Signing group"
                valueStyle={{ textTransform: "uppercase" }}
                value={file?.analysis?.signing_group ?? "unknown"}
              />
            </Col>
            <Col xs={8} sm={5}>
              <Statistic
                valueRender={(value) => {
                  return (
                    <EditLink
                      resourceName="keys"
                      recordItemId={file?.analysis?.key_id}
                    >
                      {value}
                    </EditLink>
                  );
                }}
                value={queryResult.data?.data.title}
                title={"Key"}
              />
            </Col>
          </Row>

          <AntdList
            size="small"
            bordered
            header={<Typography.Text strong>Contents</Typography.Text>}
            dataSource={file?.analysis?.files}
            renderItem={(i: any) => (
              <AntdList.Item>
                <AntdList.Item.Meta title={i.name} />
                <div>{humanFileSize(i.size)}</div>
              </AntdList.Item>
            )}
          />
        </Space>
      ) : (
        <Result
          title="No analysis results found"
          subTitle="Run an Analysis to display more details about this file"
          extra={
            <Button loading={isAnalysisLoading} onClick={onAnalyze}>
              Analyze now
            </Button>
          }
        />
      )}
    </Card>
  );
}
