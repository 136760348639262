import md5 from "md5";
import { stringify } from "query-string";
import { useMemo } from "react";
import { useUserInfo } from "./userInfo";

export function useProfile() {
  const { data: userInfo } = useUserInfo();
  return useMemo(() => {
    if (userInfo?.data) {
      return {
        avatar: `https://secure.gravatar.com/avatar/${md5(
          userInfo.data.email.toLowerCase().trim()
        )}?${stringify({
          size: 150,
          default: "identicon",
          rating: "g",
        })}`,
        name: userInfo.data.name,
        email: userInfo.data.email,
      };
    }
    return null;
  }, [userInfo]);
}
