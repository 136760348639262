import React from "react";

import { CopyOutlined } from "@ant-design/icons";
import { Button, message, Space, Typography } from "@pankod/refine-antd";

const { Text } = Typography;

type TextProps = React.ComponentProps<typeof Text> & { value: string };

const CopyToClipboard: React.FC<TextProps> = ({ value, ...textProps }) => {
  async function copyValue(e: React.MouseEvent) {
    e.preventDefault();
    await navigator.clipboard.writeText(value);
    message.success("Copied to clipboard");
  }

  return (
    <Space size="small" align="baseline">
      <Typography.Text {...textProps}>{value}</Typography.Text>
      <Button
        size="small"
        type="default"
        onClick={copyValue}
        icon={<CopyOutlined />}
      />
    </Space>
  );
};

export default CopyToClipboard;
