import {
  AntdList,
  Descriptions,
  Show,
  Space,
  Typography,
} from "@pankod/refine-antd";
import { IResourceItem, useOne, useShow } from "@pankod/refine-core";
import { DeploymentUnitOutlined } from "@ant-design/icons";
import { Event } from "interfaces/dirac/connect/depot/v1alpha1/trail";
import { EditLink, ShowLink } from "components/actionLink";
import Help from "components/help";
import { User } from "interfaces/dirac/connect/depot/v1alpha1/user";
import { LightAsync as SyntaxHighlighter } from "react-syntax-highlighter";
import { obsidian } from "react-syntax-highlighter/dist/esm/styles/hljs";
import dayjs from "dayjs";
import { ListEvents } from "./list";

const resourceProps: IResourceItem = {
  name: "events",
  icon: <DeploymentUnitOutlined />,
  list: ListEvents,
  show: ShowEvent,
};

export default resourceProps;

function ShowEvent() {
  const { queryResult } = useShow<Event>();
  const record = queryResult?.data?.data;
  const isLoading = queryResult.isLoading;
  const { data: user } = useOne<User>({
    resource: "users",
    id: record?.user!,
    queryOptions: {
      enabled: !!record,
    },
  });
  return (
    <Show title={record?.name} isLoading={isLoading}>
      <Space size="large" direction="vertical">
        <Descriptions bordered>
          <Descriptions.Item span={2} label="Method">
            {record?.method}
          </Descriptions.Item>
          <Descriptions.Item label="Called at">
            {dayjs(record?.calledAt).format("YYYY-MM-DD HH:mm")}
          </Descriptions.Item>
          <Descriptions.Item label="User">
            {record?.user ? (
              <EditLink resourceName="users" recordItemId={user?.data?.name}>
                {user?.data?.emails?.[0].email ?? "Loading..."}
              </EditLink>
            ) : (
              <>&mdash;</>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="User-Agent">
            {record?.userAgent}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <>
                Idempotency level
                <Help
                  title="Idempotency level"
                  content={
                    <>
                      <p>
                        Whether the method has any side effects on one or
                        repeated calls. Can be one of:
                      </p>
                      <ul>
                        <li>
                          <code>IDEMPOTENCY_LEVEL_UNKNOWN</code> - The state of
                          the system may change on every call
                        </li>
                        <li>
                          <code>IDEMPOTENCY_LEVEL_IMMUTABLE</code> - The call
                          has side effects, but repeated calls will put the
                          system into the same state
                        </li>
                        <li>
                          <code>IDEMPOTENCY_LEVEL_NO_SIDE_EFFECTS</code> - The
                          state of the system will not change (the operation is
                          read-only)
                        </li>
                      </ul>
                    </>
                  }
                />
              </>
            }
          >
            {record?.idempotencyLevel}
          </Descriptions.Item>
        </Descriptions>

        <>
          <Typography.Title level={4}>Referenced resources</Typography.Title>
          <AntdList bordered size="large">
            {(record?.resources.length ?? 0) > 0
              ? record?.resources.map((res) => (
                  <ResourceLink key={res} res={res} />
                ))
              : null}
          </AntdList>
        </>

        {record?.req ? (
          <>
            <Typography.Title level={4}>Request</Typography.Title>
            <SyntaxHighlighter language="json" style={obsidian}>
              {JSON.stringify(record.req, null, 4)}
            </SyntaxHighlighter>
          </>
        ) : null}

        {record?.res ? (
          <>
            <Typography.Title level={4}>Response</Typography.Title>
            <SyntaxHighlighter language="json" style={obsidian}>
              {JSON.stringify(record.res, null, 4)}
            </SyntaxHighlighter>
          </>
        ) : null}
      </Space>
    </Show>
  );
}

function ResourceLink({ res }: { res: string }) {
  const [first, , second] = res.split("/");

  let link = <>{res}</>;
  let type = "";
  if (first === "users") {
    link = (
      <EditLink resourceName="users" recordItemId={res}>
        {res}
      </EditLink>
    );
    type = "User";
  } else if (first === "projects") {
    if (!second) {
      link = (
        <EditLink resourceName="projects" recordItemId={res}>
          {res}
        </EditLink>
      );
      type = "Project";
    } else if (second === "files") {
      link = (
        <EditLink resourceName="files" recordItemId={res}>
          {res}
        </EditLink>
      );
      type = "File";
    } else if (second === "releases") {
      link = (
        <ShowLink resourceName="releases" recordItemId={res}>
          {res}
        </ShowLink>
      );
      type = "Release";
    }
  }

  return (
    <AntdList.Item>
      <AntdList.Item.Meta title={link} />
      <div>{type}</div>
    </AntdList.Item>
  );
}
