import { useList } from "@pankod/refine-core";
import {
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  SelectProps,
  Space,
  Typography,
} from "@pankod/refine-antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { LabelKey } from "interfaces/dirac/connect/depot/v1alpha1/label";
import { useDebounce } from "use-debounce";
import { useProject } from "../context/projectState";

const { Title, Text } = Typography;

interface LabelProps {
  file?: File;
  value?: Object;

  onChange?(values?: Object): void;
}

function labelsToArray(o: Object) {
  return Object.entries(o).map(([k, v]) => ({
    key: k,
    value: v,
    id: Math.random().toString(),
  }));
}

function updateTarget(
  onChange: ((values?: Object | undefined) => void) | undefined,
  newValues: { key?: string | undefined; value?: string | undefined }[]
) {
  onChange?.(
    Object.fromEntries(
      newValues.map(({ key, value }) => [key ?? "", value ?? ""])
    )
  );
}

export const Labels: React.FC<LabelProps> = ({ value, onChange }) => {
  const [values] = useState<{ key?: string; value?: string; id: string }[]>(
    value ? labelsToArray(value) : []
  );
  console.log(value);
  return (
    <>
      {values.map((currentValue, index) => (
        <Space>
          <Row key={currentValue.id} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Space size={24}>
              <Col sm={34}>
                <Title level={5}>Key</Title>
                <Text style={{ whiteSpace: "nowrap" }}>{currentValue.key}</Text>
              </Col>
              <Col sm={24}>
                <Title level={5}>Value</Title>
                <Text style={{ whiteSpace: "nowrap" }}>
                  {currentValue?.value}
                </Text>
              </Col>
            </Space>
          </Row>
        </Space>
      ))}
    </>
  );
};

export const LabelInput: React.FC<LabelProps> = ({ value, onChange }) => {
  const [values, setValues] = useState<
    { key?: string; value?: string; id: string }[]
  >(value ? labelsToArray(value) : []);

  return (
    <>
      {values.map((currentValue, index) => (
        <Space
          key={currentValue.id}
          style={{ display: "flex", flex: "1 1 auto", marginBottom: 8 }}
          align="baseline"
        >
          <Form.Item>
            <LabelKeyInput
              value={currentValue.key}
              onChange={(e) => {
                const newValues = values.map((v, i) => {
                  if (i === index) {
                    return { key: e, value: currentValue.value, id: v.id };
                  }
                  return v;
                });
                setValues(newValues);
                updateTarget(onChange, newValues);
              }}
            />
          </Form.Item>
          <Form.Item rules={[{ required: true, message: "Missing value" }]}>
            <Input
              value={currentValue.value}
              placeholder="Value"
              defaultValue={currentValue.value}
              onChange={(e) => {
                const newValues = values.map((v, i) => {
                  if (i === index) {
                    return {
                      key: currentValue.key,
                      value: e.currentTarget.value,
                      id: v.id,
                    };
                  }
                  return v;
                });
                setValues(newValues);
                updateTarget(onChange, newValues);
              }}
            />
          </Form.Item>
          <MinusCircleOutlined
            onClick={() => {
              const newValues = values.filter((v, i) => i !== index);
              setValues(newValues);
              updateTarget(onChange, newValues);
            }}
          />
        </Space>
      ))}
      <Form.Item>
        <Button
          type="dashed"
          onClick={() => {
            const newValues = [
              ...values,
              { key: "", value: "", id: Math.random().toString() },
            ];
            setValues(newValues);
            updateTarget(onChange, newValues);
          }}
          block
          icon={<PlusOutlined />}
        >
          Add label
        </Button>
      </Form.Item>
    </>
  );
};

function keyToOption(d: LabelKey) {
  return (
    <Select.Option key={d.name} value={d.name}>
      {d.name}
    </Select.Option>
  );
}

export function LabelKeyInput(props: SelectProps<string>) {
  const [project] = useProject();
  const [searchValue, setSearchValue] = useState<string>("");
  const [debouncedSearchValue] = useDebounce(searchValue, 300);
  const { data: searchResults } = useList<LabelKey>({
    resource: "label-keys",
    metaData: { parent: project },
    config: {
      filters: [
        {
          field: "keys",
          operator: "eq",
          value: `"${debouncedSearchValue}*"`,
        },
      ],
    },
  });
  const { value: propValue, onChange, ...rest } = props;

  let options = searchResults?.data.map(keyToOption);

  return (
    <AutoComplete
      value={propValue}
      style={{ width: 200 }}
      onSearch={(v) => setSearchValue(v)}
      placeholder="Key"
      defaultValue={propValue}
      onChange={(v, o) => onChange?.(v ?? "", o)}
      {...rest}
    >
      {options}
    </AutoComplete>
  );
}
