import React, { CSSProperties, useCallback, useMemo, useState } from "react";
import { useRouterContext, useTitle, useCan } from "@pankod/refine-core";
import { AntdLayout, Grid, Menu } from "@pankod/refine-antd";
import {
  SettingOutlined,
  FileZipOutlined,
  UserOutlined,
  KeyOutlined,
  TeamOutlined,
  ExperimentOutlined,
  DeploymentUnitOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import { ProjectSelect } from "../../pages/project/project";
import { Avatar } from "antd";
import { useProject } from "../../context/projectState";
import { useProfile } from "hooks/data/profile";

const antLayoutSider: CSSProperties = {
  position: "relative",
};

const antLayoutSiderMobile: CSSProperties = {
  position: "fixed",
  height: "100vh",
  zIndex: 999,
};

type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;
type MenuItems = React.ComponentProps<typeof Menu>["items"];
type MenuItem = ArrayElement<MenuItems>;

function FASider() {
  const Title = useTitle()!;
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;
  const [project, setProject] = useProject();

  const cacheOpts = {
    queryOptions: {
      staleTime: 30 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    },
  };

  const canShowProjects = useCan({
    resource: "projects",
    action: "show",
    ...cacheOpts,
  });
  const canShowUsers = useCan({
    resource: "users",
    action: "show",
    ...cacheOpts,
  });
  const canShowGroups = useCan({
    resource: "groups",
    action: "show",
    ...cacheOpts,
  });
  const canShowKeys = useCan({
    resource: "keys",
    action: "show",
    ...cacheOpts,
  });

  const canShowFiles = useCan({
    resource: project + "/files",
    action: "show",
    ...cacheOpts,
  });
  const canShowReleases = useCan({
    resource: project + "/releases",
    action: "show",
    ...cacheOpts,
  });
  const canShowEvents = useCan({
    resource: project + "/events",
    action: "show",
    ...cacheOpts,
  });

  const settingsItems = useMemo<MenuItem[]>(() => {
    const settingsItems: MenuItem[] = [];

    if (canShowUsers.data?.can)
      settingsItems.push({
        label: "Users",
        key: "/users/",
        icon: <UserOutlined />,
      });
    if (canShowGroups.data?.can)
      settingsItems.push({
        label: "Groups",
        key: "/groups/",
        icon: <TeamOutlined />,
      });
    if (canShowProjects.data?.can)
      settingsItems.push({
        label: "Projects",
        key: "/projects/",
        icon: <ExperimentOutlined />,
      });
    if (canShowKeys.data?.can)
      settingsItems.push({
        label: "Keys",
        key: "/keys/",
        icon: <KeyOutlined />,
      });
    if (canShowEvents.data?.can) {
      settingsItems.push({
        label: "Events",
        key: "/events/",
        icon: <LineChartOutlined />,
      });
    }

    return settingsItems;
  }, [
    canShowUsers,
    canShowGroups,
    canShowProjects,
    canShowKeys,
    canShowEvents,
  ]);

  const items = useMemo<MenuItem[]>(() => {
    const items: MenuItem[] = [];

    if (canShowFiles.data?.can)
      items.push({ label: "Files", key: "/files/", icon: <FileZipOutlined /> });
    if (canShowReleases.data?.can)
      items.push({
        label: "Releases",
        key: "/releases/",
        icon: <DeploymentUnitOutlined />,
      });

    items.push({ type: "divider" });
    items.push({
      label: "Settings",
      key: "settings",
      children: settingsItems,
      icon: <SettingOutlined />,
    });

    return items;
  }, [settingsItems, canShowFiles, canShowReleases]);

  //useDocumentTitle(selectedMenuItem ? `Depot - ${selectedMenuItem.label}` : "Depot");

  const projectChanged = useCallback(
    (project: string) => {
      setProject(project);
    },
    [setProject]
  );

  return (
    <AntdLayout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed: boolean) => {
        setCollapsed(collapsed);
      }}
      collapsedWidth={isMobile ? 0 : 80}
      breakpoint="lg"
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
    >
      <Title collapsed={collapsed} />

      <ProjectSelect
        collapsed={collapsed}
        onChange={projectChanged}
        value={project}
      />

      <ReactiveNavigation items={items} />

      <SiderProfile />
    </AntdLayout.Sider>
  );
}

function SiderProfile() {
  const { Link } = useRouterContext();
  const profile = useProfile();

  return (
    profile && (
      <Link
        to="/about"
        style={{
          display: "inline-block",
          height: "40px",
          marginTop: "10px",
          paddingLeft: "24px",
        }}
      >
        <Avatar size="small" src={profile.avatar} alt={profile.name} />
      </Link>
    )
  );
}

interface ReactiveNavigationProps {
  items: MenuItem[];
}

function ReactiveNavigation({ items }: ReactiveNavigationProps) {
  const { useLocation } = useRouterContext();
  const location = useLocation();
  const resourcePart = location?.pathname?.split(/\//, 2)?.[1];
  const selectedKeys = resourcePart ? [`/${resourcePart}/`] : [];

  return <SiderMenu items={items} selectedKeys={selectedKeys} />;
}

interface SiderMenuProps {
  selectedKeys: string[];
  items: MenuItem[];
}

function SiderMenu({ items, selectedKeys }: SiderMenuProps) {
  const { useHistory } = useRouterContext();
  const history = useHistory();
  const onSelect = useCallback(
    (item: MenuItem) => {
      const url = process.env.PUBLIC_URL + item?.key;
      history.push(url);
    },
    [history]
  );

  return (
    <Menu
      selectedKeys={selectedKeys}
      items={items}
      onSelect={onSelect}
      mode="inline"
    />
  );
}

export default FASider;
