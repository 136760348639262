import { NotificationProvider } from "@pankod/refine-core";
import { notification } from "antd";

export const notificationProvider: NotificationProvider = {
  open: ({
    key,
    message,
    description,
    type,
    cancelMutation,
    undoableTimeout,
  }) => {
    notification.open({
      key,
      description: message,
      message: description ?? null,
      duration: 5,
      placement: "bottomRight",
      type: type as any,
    });
  },
  close: (key) => notification.close(key),
};
