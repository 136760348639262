import React, { useCallback, useEffect, useState } from "react";
import {
  CloudDownloadOutlined,
  DeploymentUnitOutlined,
} from "@ant-design/icons";
import {
  ListStorageObjectsResponse,
  Release,
  StorageObject,
} from "interfaces/dirac/connect/depot/v1alpha1/release";
import {
  Badge,
  Button,
  ButtonProps,
  Col,
  Create,
  Form,
  Input,
  List,
  Row,
  Show,
  Space,
  Switch,
  Table,
  TextField,
  Typography,
  useForm,
  useTable,
} from "@pankod/refine-antd";
import { EditLink, ShowLink } from "components/actionLink";
import {
  CanAccess,
  IResourceItem,
  useApiUrl,
  useCustom,
  useNavigation,
  useOne,
  useShow,
} from "@pankod/refine-core";
import CopyToClipboard from "components/clipboard";
import { DateTimeField } from "components/dateTimeField";
import { FileSelect } from "components/file/select";
import { FilePicker } from "components/file/picker";
import { Operation } from "interfaces/dirac/connect/depot/v1alpha1/operation";
import humanFileSize from "../../fileSize";
import { useProject } from "../../context/projectState";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { httpClient } from "../../providers/dataProvider";
import { DownloadFileResponse } from "interfaces/dirac/connect/depot/v1alpha1/file";
import { KeySelect } from "../key/key";
const { Title, Text } = Typography;

const resourceProps: IResourceItem = {
  name: "releases",
  icon: <DeploymentUnitOutlined />,
  list: ReleaseList,
  create: ReleaseCreate,
  show: ReleaseShow,
};

export default resourceProps;

export function BuildState({ value }: { value: Release }) {
  if (value.completedAt) {
    return value.success ? (
      <Badge status="success" text="Completed" />
    ) : (
      <Badge status="error" text="Failed" />
    );
  }
  if (value.startedAt) {
    return <Badge status="processing" text="Running" />;
  }
  return <Badge status="default" text="Pending" />;
}

function ReleaseList() {
  const [project] = useProject();
  const { tableProps } = useTable<Release>({
    metaData: { parent: project },
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column<Release>
          dataIndex="version"
          title="Version"
          render={(_, record) => (
            <ShowLink recordItemId={record.name}>
              {record.version.length > 0 ? record.version : <em>No version</em>}
            </ShowLink>
          )}
        />
        <Table.Column<Release>
          dataIndex="started_at"
          title="Started"
          render={(_, record) =>
            record.startedAt ? (
              <DateTimeField value={record.startedAt} />
            ) : (
              <TextField value="&mdash;" />
            )
          }
        />
        <Table.Column<Release>
          dataIndex="completed_at"
          title="Completed"
          render={(_, record) =>
            record.completedAt ? (
              <DateTimeField value={record.completedAt} />
            ) : (
              <TextField value="&mdash;" />
            )
          }
        />
        <Table.Column<Release>
          dataIndex="public"
          title="Public"
          render={(_, record) => {
            return record.public ? <CheckOutlined /> : <CloseOutlined />;
          }}
        />
        {/*<Table.Column<Release>*/}
        {/*    title="Actions"*/}
        {/*    render={() => {*/}
        {/*        return (*/}
        {/*            <Space direction="horizontal">*/}
        {/*                <Button size="small" icon={<CopyOutlined/>}>Duplicate</Button>*/}
        {/*            </Space>*/}
        {/*        );*/}
        {/*    }}*/}
        {/*/>*/}
      </Table>
    </List>
  );
}

const semverRule = {
  pattern:
    /^([0-9]+)\.([0-9]+)\.([0-9]+)(?:-([0-9A-Za-z-]+(?:\.[0-9A-Za-z-]+)*))?(?:\+[0-9A-Za-z-]+)?$/,
  message: "Invalid version",
  required: true,
};

export function ReleaseCreate() {
  const [project] = useProject();
  const { show } = useNavigation();
  const { formProps, saveButtonProps, formLoading } = useForm<Operation>({
    successNotification: false,
    redirect: false,
    onMutationSuccess(data) {
      setOperationID(data.data.name);
    },
  });
  const [operationID, setOperationID] = useState<string>();
  const {
    data: operation,
    isLoading: isOperationLoading,
    isFetched,
  } = useOne<Operation>({
    resource: "operations",
    id: operationID!,
    queryOptions: {
      refetchInterval: 1000,
      enabled: !!operationID,
    },
  });
  const isCreating =
    formLoading ||
    (!!operationID && isOperationLoading) ||
    (isFetched && !(operation?.data?.done ?? false));
  useEffect(() => {
    if (operation?.data?.done) {
      console.log("Operation done", operation.data.response);
      if (operation.data.response) {
        show("releases", (operation.data.response as any)?.name);
      }
    }
  }, [operation, show]);

  return (
    <Create
      isLoading={formLoading || isCreating}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        wrapperCol={{ span: 14 }}
        layout="vertical"
        onFinish={(values) =>
          formProps.onFinish?.({ parent: project, ...values })
        }
      >
        <AuroraSpecInput />
        <CanAccess resource={"spec"} action={"show"}>
          <Form.Item label="Spec" shouldUpdate>
            {(instance) => {
              return (
                <pre
                  style={{
                    background: "rgba(0,0,0,0.5)",
                    padding: "1em",
                  }}
                >
                  <code>
                    {JSON.stringify(instance.getFieldsValue(), null, 4)}
                  </code>
                </pre>
              );
            }}
          </Form.Item>
        </CanAccess>
      </Form>
    </Create>
  );
}

interface AuroraSpecInputProps {
  value?: object;

  onChange?(o?: object): void;
}

const AuroraSpecInput: React.FC<AuroraSpecInputProps> = () => {
  return (
    <>
      <Form.Item
        name={["spec", "version"]}
        label="Version"
        rules={[semverRule]}
      >
        <Input placeholder="1.2.3-foo+abc" />
      </Form.Item>
      <Form.Item name={["spec", "type"]} initialValue="aurora" hidden />
      <Form.Item
        label="Key"
        name={["spec", "key"]}
        rules={[{ required: true }]}
      >
        <KeySelect />
      </Form.Item>
      <Form.Item name={["spec", "template"]} label="Template DAR:s">
        <FileSelect />
      </Form.Item>
      <Form.Item name={["spec", "mimo_filters"]} label="MIMO filters">
        <FilePicker />
      </Form.Item>
      <Form.Item name={["spec", "fiir_filters"]} label="FIIR filters">
        <FilePicker />
      </Form.Item>
      <Form.Item name={["spec", "public"]} label="Type">
        <Switch checkedChildren={"Public"} unCheckedChildren={"Internal"} />
      </Form.Item>
    </>
  );
};

export function ReleaseShow() {
  const { queryResult, showId } = useShow<Release>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const apiUrl = useApiUrl();

  const { data: storageObjects } = useCustom<ListStorageObjectsResponse>({
    url: `${apiUrl}/${showId}/objects`,
    method: "get",
  });

  return (
    <>
      <Show
        title={record && `Release ${record.version}`}
        isLoading={isLoading}
        actionButtons={
          <Space style={{ float: "right", marginRight: 24 }}>
            <DownloadReleaseButton release={showId!} type="primary">
              Download tarball
            </DownloadReleaseButton>
          </Space>
        }
      >
        <Space direction="vertical" size="large">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col sm={6}>
              <Title level={5}>Version</Title>
              <Text style={{ whiteSpace: "nowrap" }}>{record?.version}</Text>
            </Col>
            <Col sm={18}>
              <Title level={5}>Name</Title>
              {record?.name && <CopyToClipboard value={record.name} />}
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col sm={6}>
              <Title level={5}>State</Title>
              <Text>{record && <BuildState value={record} />}</Text>
            </Col>
            <Col sm={18}>
              <Title level={5}>Output URL</Title>
              {record?.bucket ? (
                <CopyToClipboard
                  value={`s3://${record.bucket}/${record.prefix}`}
                />
              ) : (
                <>&mdash;</>
              )}
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col sm={6}>
              <Title level={5}>Type</Title>
              <Text>{record?.public ? "Public" : "Internal"}</Text>
            </Col>
          </Row>
          <pre className="terminal-log">
            <code>{record?.logExcerpt}</code>
          </pre>

          <Table
            dataSource={storageObjects?.data.storageObjects}
            rowKey="key"
            pagination={false}
          >
            <Table.Column<StorageObject>
              dataIndex="file"
              title="File"
              render={(_, record) =>
                record.file ? (
                  <EditLink resourceName="files" recordItemId={record.file}>
                    {record.file}
                  </EditLink>
                ) : (
                  <Typography.Text>&mdash;</Typography.Text>
                )
              }
            />
            <Table.Column dataIndex="key" title="Key" />
            <Table.Column<StorageObject>
              dataIndex="lastModified"
              title="Last Modified"
              render={(_, record) => (
                <DateTimeField value={record.lastModifiedAt} />
              )}
            />
            <Table.Column<StorageObject>
              dataIndex="size"
              title="Size"
              render={(_, record) => (
                <TextField value={humanFileSize(record.size)} />
              )}
            />
          </Table>
        </Space>
      </Show>
    </>
  );
}

interface DownloadReleaseButtonProps extends ButtonProps {
  release: string | number;
  path?: string;
}

function DownloadReleaseButton(props: DownloadReleaseButtonProps) {
  const apiUrl = useApiUrl();
  const { release, ...rest } = props;

  const [loading, setLoading] = useState(false);
  const onClick = useCallback(async () => {
    setLoading(true);
    const data = await httpClient.get<DownloadFileResponse>(
      `${apiUrl}/${release}:download`
    );
    window.location.href = data?.data.url;
    setLoading(false);
  }, [apiUrl, release]);

  return (
    <Button
      onClick={onClick}
      icon={<CloudDownloadOutlined />}
      loading={loading}
      download
      {...rest}
    ></Button>
  );
}
