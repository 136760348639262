import {
  BooleanField,
  FilterDropdown,
  List,
  Select,
  Table,
  useTable,
} from "@pankod/refine-antd";
import { getDefaultFilter, useMany } from "@pankod/refine-core";
import { Event } from "interfaces/dirac/connect/depot/v1alpha1/trail";
import { DateTimeField } from "components/dateTimeField";
import { EditLink, ShowLink } from "components/actionLink";
import { User } from "interfaces/dirac/connect/depot/v1alpha1/user";

export function ListEvents() {
  const { filters, tableProps } = useTable<Event>({
    initialFilter: [
      {
        field: "idempotency_level",
        value: "IDEMPOTENCY_LEVEL_UNKNOWN",
        operator: "eq",
      },
    ],
  });

  const { data: usersData, isLoading: usersIsLoading } = useMany<User>({
    resource: "users",
    ids: tableProps?.dataSource?.map((i) => i.user)!,
    queryOptions: {
      enabled: !!tableProps.dataSource,
    },
  });

  const usersById = usersData?.data
    ? Object.fromEntries(usersData?.data?.map((user) => [user.name, user]))
    : {};
  const defaultIdempotencyLevel = getDefaultFilter(
    "idempotency_level",
    filters,
    "eq"
  );

  return (
    <List>
      <Table {...tableProps}>
        <Table.Column<Event>
          title="Method"
          dataIndex="method"
          render={(_, record) => {
            const p = record.method.split("/");
            return (
              <ShowLink resourceName="events" recordItemId={record.name}>
                {p && p[p.length - 1]}
              </ShowLink>
            );
          }}
        />
        <Table.Column<Event>
          title="Time"
          dataIndex="calledAt"
          render={(_, record) => {
            return <DateTimeField value={record.calledAt} />;
          }}
        />
        <Table.Column<Event>
          title="User"
          dataIndex="user"
          render={(_, record) =>
            usersById?.[record.user] ? (
              <EditLink resourceName="users" recordItemId={record.user}>
                {usersById?.[record.user]?.emails[0].email}
              </EditLink>
            ) : usersIsLoading ? (
              <>Loading...</>
            ) : (
              <>&mdash;</>
            )
          }
        />
        <Table.Column<Event>
          title="Read-Only"
          dataIndex="idempotency_level"
          key="idempotency_level"
          render={(_, record) => (
            <BooleanField
              value={
                (record.idempotencyLevel as any) ===
                "IDEMPOTENCY_LEVEL_NO_SIDE_EFFECTS"
              }
            />
          )}
          defaultFilteredValue={getDefaultFilter(
            "idempotency_level",
            filters,
            "eq"
          )}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                placeholder="Idempotency level"
                defaultValue={defaultIdempotencyLevel}
                options={[
                  { label: "Unknown", value: "IDEMPOTENCY_LEVEL_UNKNOWN" },
                  {
                    label: "Idempotent",
                    value: "IDEMPOTENCY_LEVEL_IDEMPOTENT",
                  },
                  {
                    label: "No side effects",
                    value: "IDEMPOTENCY_LEVEL_NO_SIDE_EFFECTS",
                  },
                ]}
              />
            </FilterDropdown>
          )}
        />
      </Table>
    </List>
  );
}
